import React from "react";
import Login from "../Pages/Login";
import Register from "../Pages/Register";


const publicRoutes = [
  { path: "/desk", element: <Login /> },
  { path: "/register", element: <Register /> },
];

export default publicRoutes;