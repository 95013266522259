import React, { useEffect, useState } from "react";
import {
  Image,
  Stack,
  Heading,
  Flex,
  Box,
  Text,
  Button,

} from "@chakra-ui/react";
import img1 from "../assets/img/aboutus2.jpg";
import aboutus from "../assets/img/aboutus.png";
import "./css/style.css";
import {

  PhoneIcon,


} from "@chakra-ui/icons";
import Header from "./component/Header";
import Footer from "./component/Footer";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <div className="container">
      <Helmet>
        <title>About Us | Learn More About Canbridge Global | Trivandrum</title>
        <meta
          name="description"
          content="Discover the story behind Trivandrum's leading Study abroad Consultancy, Canbridge can help you achieve your international education goals"
        />
        <link rel="canonical" href="https://www.canbridge.in/aboutus" />
        <meta
          property="og:title"
          content="About Us | Learn More About Canbridge Global | Trivandrum"
        />
        <meta
          property="og:description"
          content="Discover the story behind Trivandrum's leading Study abroad Consultancy, Canbridge can help you achieve your international education goals"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="../assets/img/abc.png" />
        <meta property="og:url" content="https://www.canbridge.in" />
        <meta property="og:locale" content="en_US" />
        <meta
          name="keywords"
          content="Canbridge Consultancy, About Canbridge, Study Abroad Trivandrum, Education Consultants, Overseas Education"
        ></meta>
      </Helmet>
      {/* Header */}
      <Header />
      {/* cover banner */}
      <Box
        bgImage={img1}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize={"cover"}
        h="250"
        mt={"80px"}
      ></Box>
      <Box p={{ base: "1vw", md: "10vw" }}>
        {/* <Flex ms={'20'} p={'50'} >
                    <Stack>
                        <Heading> About Us</Heading>
                        <Heading size={'sm'}> Some introduction here </Heading>
                    </Stack>
                </Flex> */}

        <Flex direction={{ base: "column", md: "row" }}>
          <Box p={"10"} w={{ base: "100%", md: "50%" }}>
            <Box>
              <Image src={aboutus} alt="Dan Abramov" />
            </Box>
          </Box>
          <Box p={"10"} w={{ base: "100%", md: "50%" }}>
            <Stack>
              <Heading as={'h2'} size={"md"}>Welcome to Canbridge Global</Heading>
              <Heading>Our goal is to help you realize your dreams</Heading>
              <Text>
                At Canbridge, we are dedicated to helping individuals achieve
                their educational and career goals through personalized guidance
                and expert advice. Our consultancy specializes in study visas
                for various countries including Canada, the UK, Australia, and
                Germany, as well as immigration to destinations like Australia,
                New Zealand, and Canada. Trust our team of knowledgeable
                professionals who stay updated on the latest immigration laws
                and visa regulations to assist you throughout the application
                process. From initial submission to acceptance confirmation and
                pre-departure orientation, we offer comprehensive support and
                recommendations for your study and immigration options. We also
                offer language programs to help you meet the language
                requirements of your desired country. Count on us for our
                extensive understanding of immigration laws, educational systems
                in different countries, and our courses catering to IELTS,
                TOEFL, and OET exams, as well as German language levels A1, A2,
                and B1.
              </Text>
              <Box mt="1">
                <Button
                  as="a"
                  href="tel:+917777977582"
                  color={"white"}
                  leftIcon={<PhoneIcon />}
                  ml="2"
                  bg={"#4831D4"}
                  _hover={{
                    bg: "#3FD1FF",
                    color: "#202023",
                    transform: "scale(1.05)",
                    boxShadow: "lg",
                  }}
                >
                  Enquire Now
                </Button>
              </Box>
            </Stack>
          </Box>
        </Flex>
      </Box>
      <Footer />{" "}
    </div>
  );
};

export default About;
