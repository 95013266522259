import React from "react";
import About from "../WebSite/About";
import ComingSoon from "../WebSite/comingSoon";
import Home from "../WebSite/HomePage";
import Product from "../WebSite/Product";


const WebRoutes = [
  // { path: "/", element: <ComingSoon /> },
  { path: "/", element: <Home /> },
  { path: "/aboutus", element: <About /> },
  { path: "/opportunity/:heading",element:<Product/>}

];

export default WebRoutes;