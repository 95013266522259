import { React, useEffect, useState } from 'react'
import mainservice from '../Services/mainservice';
import { Grid, Box, Text, Button, IconButton, Flex } from '@chakra-ui/react';
import { EditIcon, DeleteIcon, ViewIcon } from '@chakra-ui/icons';

function Ourblogs() {

    const [blog, setBlog] = useState([]);

    const GetProduct = async () => {
        try {

            const response = await mainservice.GetAllBlogs();
            setBlog(response.data);
            console.log(response);

        } catch (error) {
            console.error('Error fetching product:', error);
        }
    };

    useEffect(() => {
        GetProduct();
    }, []);

    return (
        <div style={{ fontFamily: "Arial, sans-serif", margin: 0, padding: 0, backgroundColor: "#f9f9f9", color: "#333" }}>
            <header style={{ backgroundColor: "#3182ce", color: "white", padding: "20px", textAlign: "center" }}>
                <h1>Welcome to My Blog</h1>
                <p>Sharing thoughts, ideas, and stories</p>
            </header>

            <div style={{ maxWidth: "1200px", margin: "auto", padding: "20px"}}>
                <section style={{ textAlign: "center", padding: "50px", backgroundColor: "#e9ecef", marginBottom: "20px" }}>
                    <h1>Discover Amazing Stories</h1>
                    <p>Your journey to knowledge and inspiration starts here.</p>
                </section>
             <br/>
                  
                    {blog.map((item) => (
                      

                        <Box key={item} borderWidth="1px" borderRadius="lg" p={4} boxShadow="md" >
                            <Flex direction="row" align="center" justify="space-between">
                                {/* Title Section */}
                                <Text fontSize="xl" fontWeight="bold">{item.title}</Text>
                                {/* <img src={blog.featuredImage} alt="Blog Featured" /> */}
                                {/* Buttons Section */}
                                <Flex>
                                    <Button leftIcon={<ViewIcon />} variant="outline" colorScheme="teal" mr={2}>
                                        
                                    </Button>
                                    <IconButton
                                        icon={<EditIcon />}
                                        variant="outline"
                                        colorScheme="blue"
                                        aria-label="Edit"
                                        mr={2}
                                    />
                                    <IconButton
                                        icon={<DeleteIcon />}
                                        variant="outline"
                                        colorScheme="red"
                                        aria-label="Delete"
                                    />
                                </Flex>
                            </Flex>
                        </Box>
                    ))}
            </div>
        </div>
    )
}

export default Ourblogs