

import React from 'react';


const Register = () => {
  return (
    <div>
    Register here
    </div>
  );
};

export default Register;
