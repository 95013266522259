

import React, { useEffect, useState } from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    Radio,
    useDisclosure,
    RadioGroup,
    Stack,
    Box,
    Center,
    Heading,
    Text, Image
} from '@chakra-ui/react'
import { CiEdit } from "react-icons/ci";

import { chakra } from "@chakra-ui/react"
import AutoResize from "react-textarea-autosize"
import { MdDeleteForever } from "react-icons/md";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    Flex,
    Textarea
} from '@chakra-ui/react'
import mainservice from '../Services/mainservice';
import { useSearchParams } from 'react-router-dom';


const CMS = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
    const { isOpen: isModalOpen2, onOpen: onModalOpen2, onClose: onModalClose2 } = useDisclosure();
    const [product, setProduct] = useState({
        Country: '',
        Category: '',
        Content: []
    });

    // Ensure dependencies are correct

    const [form, setForm] = useState({});
    const [form2, setForm2] = useState({});
    const [content, setContent] = useState({});

    const onChangeHandler = (event) => {
        const { name, value } = event.target;
        setForm(prevForm => ({
            ...prevForm,
            [name]: value
        }));
    };
    const onChangeHandler2 = (event) => {
        const { name, value } = event.target;
        setForm2(prevForm => ({
            ...prevForm,
            [name]: value
        }));
    };

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        // Prepare the complete content data with list items
        const contentData = {
            ...form,
            List: listItems  // Add list items to the form data
        };

        try {
            const res = await mainservice.PostContent(contentData, product._id);
            if (res.data) {
                onModalClose();
                GetProduct(ContentID);
                setForm({})
                setListItems([])// Refresh the product content after submission
            } else {
                console.error('Unexpected response:', res);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };
    const [listItems, setListItems] = useState([]);
    const addListItem = () => setListItems([...listItems, '']);

    // Update specific list item
    const onListItemChange = (e, index) => {
        const updatedList = [...listItems];
        updatedList[index] = e.target.value;
        setListItems(updatedList);
    };

    const onSubmitHandler2 = async (event) => {
        event.preventDefault();


        try {
            const res = await mainservice.PostUniversity(form2, product._id);
            if (res.data) {
                onModalClose2()

                GetProduct(ContentID)
                setForm2({})
                setListItems([])
            } else {
                console.error('Unexpected response:', res);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const [searchParams, setSearchParams] = useSearchParams();
    const ContentID = searchParams.get('id');


    const GetProduct = async (id) => {
        try {
            // Await the product data from the service
            const productData = await mainservice.GetProductByIdpro(id);
            // Update the state with the fetched product data
            setProduct(productData.data);

        } catch (error) {
            // Handle any errors that occur during fetch
            console.error('Error fetching product:', error);
        }
    };

    // edit the form
    const [editingIndex, setEditingIndex] = useState(null); // Track index of item being edited
    const [currentItemData, setCurrentItemData] = useState(null); // Temporary state for editable data


    const startEditing = (index) => {
        setEditingIndex(index);
        setCurrentItemData({ ...product.Content[index] }); // Set initial editable data
        console.log(product.Content[index]);


    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentItemData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleListChange = (index, value) => {
        const updatedList = [...currentItemData.List];
        updatedList[index] = value;
        setCurrentItemData((prev) => ({
            ...prev,
            List: updatedList,
        }));
    };

    const cancelEdit = () => {
        setEditingIndex(null);
        setCurrentItemData(null);
    };

    const submitChanges = () => {
        // Create a copy of the product content to avoid mutating state directly
        const updatedContent = [...product.Content];

        // Update the specific item
        updatedContent[editingIndex] = currentItemData;

        // Call PutContent to save the changes to the backend
        PutContent(currentItemData, ContentID, currentItemData._id);

        // Reset editing index and current item data after submission
        setEditingIndex(null);
        setCurrentItemData(null);
    };

    const handleDeleteListItem = (idx) => {
        // Remove the specific item from the currentItemData.List
        const updatedList = currentItemData.List.filter((_, index) => index !== idx);
        setCurrentItemData({ ...currentItemData, List: updatedList });
    };

    // Function to handle adding a new point to the List
    const handleAddPoint = () => {
        const updatedList = [...currentItemData.List, ""]; // Empty string as a new list item
        setCurrentItemData({ ...currentItemData, List: updatedList });

    };

    // update content
    const PutContent = async (data, prodId, ContId) => {
        try {
            console.log(data, "id", ContId);
            const productData = await mainservice.PutContent(data, prodId, ContId);
            GetProduct(ContentID);

        } catch (error) {
            console.error('Error updating product:', error);
        }
    };

    const [isEditingSmallIntro, setIsEditingSmallIntro] = useState(false); // New state for editing mode
    const [smallIntro, setSmallIntro] = useState(""); // Temporary state to hold SmallIntro edits
    console.log(product.SmallIntro);

    const handleSaveSmallIntro = async () => {
        try {
            const updatedProduct = { SmallIntro: smallIntro };
            await mainservice.UpdateProductById(updatedProduct, product._id); // Assuming PutContent updates product
            setSmallIntro(updatedProduct.SmallIntro); // Update local state
            GetProduct(ContentID)
            setIsEditingSmallIntro(false); // Exit edit mode
        } catch (error) {
            console.error('Error updating SmallIntro:', error);
        }
    };

    const onSubmitDeleteById = async (productId, contentId) => {
        try {
            const response = await mainservice.DeleteProductById(productId, contentId);
            GetProduct(ContentID);
            console.log(response);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const onSubmitDeleteUniversityById = async (productId, contentId) => {
        try {
            const response = await mainservice.DeleteUniversityById(productId, contentId);
            GetProduct(ContentID);
            console.log(response);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        GetProduct(ContentID);
    }, []);

    useEffect(() => {
        setSmallIntro(product.SmallIntro);
    }, [product]);


    return (
        <>
            <Flex justify={'space-between'}>
                <Button colorScheme='blue' onClick={onOpen}>
                    Open
                </Button>
                <Drawer placement='left' onClose={onClose} isOpen={isOpen}>
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerHeader borderBottomWidth='1px'>Basic Drawer</DrawerHeader>
                        <DrawerBody>
                            <p>Some contents...</p>
                            <p>Some contents...</p>
                            <p>Some contents...</p>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
                <Stack> <Button onClick={onModalOpen}>Post Content</Button>
                    <Button onClick={onModalOpen2}>Add University</Button> </Stack>
            </Flex>
            <Modal isOpen={isModalOpen} onClose={onModalClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>New Content</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack spacing={4}>
                            <Input onChange={onChangeHandler} name='Heading' variant='filled' placeholder='Heading' />
                            <Textarea onChange={onChangeHandler} name="Paragraph" variant='filled' placeholder='Description' />

                            <Stack spacing={2}>
                                <Text fontSize="sm" fontWeight="bold">List Items</Text>
                                {listItems.map((item, index) => (
                                    <Input
                                        key={index}
                                        variant="filled"
                                        placeholder={`List item ${index + 1}`}
                                        value={item}
                                        onChange={(e) => onListItemChange(e, index)}
                                    />
                                ))}
                                <Button size="sm" onClick={addListItem}>Add List Item</Button>
                            </Stack>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onSubmitHandler}>
                            Add
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={isModalOpen2} onClose={onModalClose2}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader> Add New University</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack>
                            <Input onChange={onChangeHandler2} name='Name' variant='filled' placeholder='University' />
                            <Textarea onChange={onChangeHandler2} name="Link" variant='filled' placeholder='URL' />
                        </Stack>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onSubmitHandler2}>
                            Add
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Box p={{ base: '1vw', md: '10vw' }}>
                <Flex direction={{ base: 'column', md: 'row' }}>
                    <Box
                        p={"5%"}
                        pt={{ base: "10", md: "0" }}
                        w={{ base: "100%", md: "65%" }}
                    >
                        <Stack spacing={4} mb={6}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Heading size={"xl"} textAlign="center">
                                    Study in {product.Country}
                                </Heading>
                                <CiEdit size={30} style={{ cursor: 'pointer' }} onClick={() => setIsEditingSmallIntro(true)} />
                            </div>
                            {isEditingSmallIntro ? (
                                <Stack spacing={3}>
                                    <Textarea

                                        value={smallIntro}
                                        onChange={(e) => setSmallIntro(e.target.value)}
                                        placeholder="Edit Small Intro"
                                        style={{ width: 'auto', height: '300px' }}
                                    />
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                                        <Button mt={3} onClick={() => handleSaveSmallIntro()}>
                                            Save
                                        </Button>
                                        <Button mt={3} onClick={() => setIsEditingSmallIntro(false)}>
                                            Cancel
                                        </Button></div>
                                </Stack>
                            ) : (
                                <Text fontSize="lg" textAlign="center">
                                    {product.SmallIntro}
                                </Text>
                            )}
                        </Stack>
                        {product.Content.length > 0 ? (
                            product.Content.map((item, index) => (
                                <Stack key={index} p={5} mb={5}>
                                    {editingIndex === index ? (
                                        <>
                                            <form>
                                                <Input
                                                    name="Heading"
                                                    value={currentItemData.Heading}
                                                    onChange={handleInputChange}
                                                    placeholder="Edit heading"
                                                    fontFamily={'-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol'}
                                                    style={{ fontSize: '15px', fontWeight: 'bold' }}
                                                />
                                                <Textarea
                                                    name="Paragraph"
                                                    rows={4}
                                                    value={currentItemData.Paragraph}
                                                    onChange={handleInputChange}
                                                    placeholder="Edit paragraph"
                                                    height={'200px'}
                                                />

                                                {currentItemData.List && currentItemData.List.length > 0 && (
                                                    <Stack spacing={1} mt={3}>
                                                        {currentItemData.List.map((listItem, idx) => (
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}><Input
                                                                key={idx}
                                                                value={listItem}
                                                                onChange={(e) => handleListChange(idx, e.target.value)}
                                                                placeholder={`Edit list item ${idx + 1}`} />
                                                                <MdDeleteForever
                                                                    size="30px"
                                                                    style={{ cursor: 'pointer', color: 'red' }}
                                                                    onClick={() => handleDeleteListItem(idx)}
                                                                />
                                                            </div>
                                                        ))}
                                                    </Stack>
                                                )}
                                                <Button mt={3} ml={2} w="full" onClick={handleAddPoint} variant="outline">Add List</Button> {/* Add Point Button */}
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                                                    <Button mt={3} onClick={cancelEdit}>Cancel</Button>
                                                    <Button mt={3} ml={2} onClick={submitChanges}>Submit</Button>
                                                </div>
                                            </form>
                                        </>
                                    ) : (
                                        <>
                                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                <div>
                                                    <Heading size="md">{item.Heading}</Heading>
                                                </div>
                                                <div size={100} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <CiEdit onClick={() => startEditing(index)} size={30} style={{ cursor: 'pointer', marginRight: '10px' }} />
                                                    <MdDeleteForever
                                                        size="30px"
                                                        onClick={() => onSubmitDeleteById(ContentID, item._id)} // Use listItem._id for deletion
                                                        style={{ cursor: 'pointer', color: 'red' }}
                                                    />
                                                </div>
                                            </div>

                                            <Text fontSize="md">{item.Paragraph}</Text>
                                            {item.List && item.List.length > 0 && (
                                                <Stack as="ul" spacing={1} pl={6} listStyleType="disc">
                                                    {item.List.map((listItem, idx) => (
                                                        <Text key={idx} as="li" fontSize="md" color="gray.700">
                                                            {listItem}
                                                        </Text>
                                                    ))}
                                                </Stack>
                                            )}
                                        </>
                                    )}
                                </Stack>
                            ))
                        ) : (
                            <Text textAlign="center" color="gray.500">
                                No content available for this product.
                            </Text>
                        )}

                    </Box>
                    <Box p={'10'} w={{ base: '100%', md: '30%' }}>
                        <Box mb='10'>
                            <Heading size={'md'}>Top Universities</Heading>
                            <Flex p={'2'} mt={'5'}>
                                <Box minW={"200px"} ml='3' alignContent={'center'}>
                                    {product.University ? product.University.map((item, index) => {
                                        return <Stack>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Text cursor="pointer" onClick={() => window.open(item.Link, '_blank')} fontWeight='bold' key={index} >
                                                    {item.Name}
                                                </Text><br />
                                                <MdDeleteForever
                                                    size="30px"
                                                    onClick={() => onSubmitDeleteUniversityById(ContentID, item._id)}
                                                    style={{ cursor: 'pointer', color: 'red' }}
                                                /></div>
                                        </Stack>
                                    }) : <></>}
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
                </Flex>
            </Box>
        </>
    )
};

export default CMS;
