
import apicall from './interceptor'

async function Login(data) {
  const response = await apicall.apicall('post', 5000, 'auth/login', data)
  return response
}
async function Auth(data) {
  const response = await apicall.apicall('post', 5000, 'auth/verify', data)
  return response
}
async function GetProduct() {
  const response = await apicall.apicall('get', 5000, 'product/getallproduct')
  return response
}
async function GetProductById(id) {
  const response = await apicall.apicall('get', 5000, `product/getproductId/${id}`)
  return response
}
async function GetProductByIdpro(id) {
  const response = await apicall.apicall('get', 5000, `product/getproductIdpro/${id}`)
  return response
}
async function PostProduct(data) {
  const response = await apicall.apicall('post', 5000, 'product/createproduct', data)
  return response
}
async function PostContent(data, id) {
  const response = await apicall.apicall('post', 5000, `product/postcontent/${id}`, data)
  return response
}
async function GetAllCountry() {
  const response = await apicall.apicall('get', 5000, `product/getallcountry`)
  return response
}
async function ContactForm(data) {
  const response = await apicall.apicall('post', 5000, `form/contact`, data)
  return response
}
async function PostUniversity(data, id) {
  const response = await apicall.apicall('post', 5000, `product/postunversity/${id}`, data)
  return response
}
async function PutContent(data, product_id, content_id) {
  const response = await apicall.apicall('put', 5000, `product/putcontent/${product_id}/${content_id}`, data)
  return response
}

async function DeleteProduct(product_id,) {
  const response = await apicall.apicall('delete', 5000, `product/deleteproduct/${product_id}`)
  return response
}

async function DeleteProductById(product_id, content_id) {
  const response = await apicall.apicall('delete', 5000, `product/deletecontent/${product_id}/${content_id}`)
  console.log(product_id, content_id);
  return response
}

async function DeleteUniversityById(product_id, University_id) {
  const response = await apicall.apicall('delete', 5000, `product/deleteuniversity/${product_id}/${University_id}`)
  console.log(product_id, University_id);
  return response
}

// async function UpdateUniversityById(data,product_id,University_id) {
//   const response = await apicall.apicall('put', 5000, `product/putuniversity/${product_id}/${University_id}`,data)
//   console.log(product_id,University_id);
//   return response
// }

async function UpdateProductById(data, product_id) {
  const response = await apicall.apicall('put', 5000, `product/updateproductId/${product_id}`, data)
  return response
}

async function PostBlog(data) {
  const response = await apicall.apicall('post', 5000, `blog/create-post`, data)
  return response
}

async function GetAllBlogs() {
  const response = await apicall.apicall('get', 5000, `blog/getpost`)
  return response
}

// async function GetFiles() {
//   const response = await apicall.apicall('get', 5000, `blog/${filename_id}`)
//   return response
// }

async function PostTag(data) {
  const response = await apicall.apicall('post', 5000, `createtag`, data)
  return response
}

async function GetAllTags() {
  const response = await apicall.apicall('get', 5000, `tag/getalltag`)
  console.log(response.data);
  return response
}


export default {
  Login, Auth, GetProduct, PostProduct, GetProductById, PostContent, GetAllCountry, ContactForm, PostUniversity, GetProductByIdpro, PutContent, DeleteProduct,
  DeleteProductById, DeleteUniversityById, UpdateProductById, PostBlog, GetAllBlogs, PostTag, GetAllTags

}



// import apicall from './interceptor';

// // Generic function to handle API calls
// async function apiHandler(method, route, data = null) {
//   try {
//     const response = await apicall.apicall(method, null, route, data);
//     return response;
//   } catch (error) {
//     console.error(`${method.toUpperCase()} ${route} failed:`, error);
//     throw error; // or return a user-friendly error message
//   }
// }

// // API functions
// const Login = (data) => apiHandler('post', 'auth/login', data);
// const Auth = (data) => apiHandler('post', 'auth/verify', data);
// const GetProduct = () => apiHandler('get', 'product/getallproduct');
// const PostProduct = (data) => apiHandler('post', 'product/createproduct', data);

// export default {
//   Login,
//   Auth,
//   GetProduct,
//   PostProduct,
// };
