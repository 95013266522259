import React, { useEffect, useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Radio,
  useDisclosure,
  RadioGroup,
  Stack,
  Box,
  Center,
  Select,
} from "@chakra-ui/react";

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Flex,
} from "@chakra-ui/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
} from "@chakra-ui/react";
import mainservice from "../Services/mainservice";
import { useNavigate } from "react-router-dom";
import { GrView } from "react-icons/gr";

import { MdDeleteForever } from "react-icons/md";


const Dashboard = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();
  const [product, setProduct] = useState([]);

  const GetProduct = async () => {
    try {
      // Await the product data from the service
      const productData = await mainservice.GetProduct();
      // Update the state with the fetched product data
      setProduct(productData.data);
      console.log(productData.data);
    } catch (error) {
      // Handle any errors that occur during fetch
      console.error("Error fetching product:", error);
    }
  };

  useEffect(() => {
    GetProduct();
  }, []); // Ensure dependencies are correct

  const [form, setForm] = useState({});

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
    console.log("Updated form:", form);
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    console.log("Submitting form:", form);

    try {
      const res = await mainservice.PostProduct(form);
      if (res.data) {
        onModalClose();
        console.log("Success response:", res);
        GetProduct();
      } else {
        console.error("Unexpected response:", res);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const navigate = useNavigate();

  const onSubmitDelete = async (productId) => {
    // setIsDeleting(true); // Start the deletion process

    try {
      const response = await mainservice.DeleteProduct(productId); // Pass empty data if it's not required
      // if (response.success) {
      //   console.log('Product deleted successfully');
      //   // You can redirect or show a success message here
      // } else {
      GetProduct();
      console.error('Error deleting product');
      // }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <Flex justify={"space-between"}>
        <Button colorScheme="blue" onClick={onOpen}>
          Open
        </Button>
        <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth="1px">Basic Drawer</DrawerHeader>
            <DrawerBody>
              <p>Some contents...</p>
              <p>Some contents...</p>
              <p>Some contents...</p>
            </DrawerBody>
          </DrawerContent>
        </Drawer>

        <Button onClick={onModalOpen}>Add New Programme</Button>
      </Flex>

      <Modal isOpen={isModalOpen} onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add new Programme</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Input
                onChange={onChangeHandler}
                name="Country"
                variant="filled"
                placeholder="Country"
              />
              <Input
                onChange={onChangeHandler}
                name="CountryCode"
                variant="filled"
                placeholder="Country Code"
              />

              {/* Change Category input to a Select option */}
              <Select
                onChange={onChangeHandler}
                name="Category"
                variant="filled"
                placeholder="Select Category"
              >
                <option value="Study Abroad">Study Abroad</option>
                <option value="MBBS">MBBS</option>
                <option value="Emigration">Emigration</option>
                <option value="Others">Others</option>
                {/* Add more options as needed */}
              </Select>

              <Input
                onChange={onChangeHandler}
                name="SmallIntro"
                variant="filled"
                placeholder="Introduction"
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onSubmitHandler}>
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box>
        <Center>
          <Box>
            <TableContainer>
              <Table variant="simple">
                <TableCaption>Study Abroad List</TableCaption>
                <Thead>
                  <Tr>
                    <Th>Country</Th>
                    <Th>Category</Th>
                    <Th isNumeric>Manage</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {product.map((item, index) => (
                    <Tr key={index}>
                      <Td>{item.Country}</Td>
                      <Td>{item.Category}</Td>
                      <Td>
                        <Button style={{ cursor: 'pointer' }}
                          // size="25px"
                          onClick={() => {
                            navigate(`/dashboard/cms?id=${item._id}`);
                          }}
                        >view
                        </Button>
                      </Td>
                      <Td>
                        <MdDeleteForever style={{ cursor: 'pointer' }}
                          size="30px"
                          onClick={() => onSubmitDelete(item._id)}
                        />
                      </Td>

                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Center>
      </Box>
    </>
  );
};

export default Dashboard;
