'use client'
import React, { useState, useEffect, Suspense, lazy } from 'react';

// Dynamically import ReactQuill with React.lazy to prevent SSR errors





// import ReactQuill, { Quill } from 'react-quill';
import Select from 'react-select';
import 'react-quill/dist/quill.snow.css';
import {
    Grid, GridItem, Box,
    Input,
    Tag,
    TagCloseButton,
    TagLabel,
    Wrap,
    WrapItem,
    Flex,
} from "@chakra-ui/react"
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormLabel,
    FormControl
} from '@chakra-ui/react'
import mainservice from '../Services/mainservice';
import { Button } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
// import { DecorativeBox } from "compositions/lib/decorative-box"



function Blog() {
    const ReactQuill = lazy(() => import('react-quill'));
    const [value, setValue] = useState('');
    // console.log(value);
    // useEffect(() => {


    // }, [setValue]);

    const [formData, setFormData] = useState({
        title: '',
        slug: '',
        // content: '', // Used for ReactQuill content
        excerpt: '',
        featuredImage: '',
        status: 'draft',
        metaTitle: '',
        metaDescription: '',
        keywords: [],
        commentsEnabled: true,
        relatedPosts: []
    });

    // Handle standard input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        console.log(formData);

    };

    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0]; // Get the first file
        console.log(file);

        if (file) {
            // Check if the file is an image
            if (file.type.startsWith('image/')) {
                setImage(file); // Set the file as image
                setImagePreview(URL.createObjectURL(file)); // Set the preview URL for the image
            } else {
                alert('Please upload a valid image file');
            }
        }
    };

    // Handle ReactQuill content changes
    const handleQuillChange = (value) => {
        setFormData({ ...formData, content: value });
    };

    // const handleKeywordsChange = (e) => {
    //     setFormData({ ...formData, keywords: e.target.value.split(',') });
    // };

    function titleToSlug(title) {
        return title
            .toLowerCase()                // Convert to lowercase
            .trim()                       // Remove whitespace from both ends
            .replace(/[^a-z0-9\s-]/g, '') // Remove special characters
            .replace(/\s+/g, '-')         // Replace spaces with hyphens
            .replace(/-+/g, '-');         // Replace multiple hyphens with a single one
    }


    const handleSave = async () => {
        // Log the current formData for debugging purposes
        console.log('Form Data:', formData);

        // Initialize a FormData object
        const formDataToSend = new FormData();

        // Append text data and keywords to FormData
        formDataToSend.append('formData', JSON.stringify({
            ...formData,

            slug: titleToSlug(formData.title),
            author: '6740340f3a430a098930a360',
            excerpt: "This is a short excerpt of the post.",
            seo: {
                keywords: tags,
                metaTitle: formData.title,
                metaDescription: formData.metaDescription
            }


        }));
        console.log('Meta Description:', formData.metaDescription);
        // Append the image file
        formDataToSend.append('file', image);

        // Log the formDataToSend to verify its contents
        console.log('FormData to Send:', formDataToSend);

        try {
            // Replace with your actual API call to save blog data
            const response = await mainservice.PostBlog(formDataToSend);

            // Log the response for debugging
            console.log('Response:', response);
        } catch (error) {
            console.error('Error while saving blog:', error);
        }
    };

    const { isOpen, onOpen, onClose } = useDisclosure()

    // State to manage tags
    const [tags, setTags] = useState([]);

    // State to manage input value
    const [inputValue, setInputValue] = useState("");

    // Function to handle adding a tag
    const handleAddTag = (event) => {
        if (event.key === "Enter" && inputValue.trim() !== "") {
            setTags([...tags, inputValue.trim()]); // Add new tag
            setInputValue(""); // Clear input field
        }
    };

    // Function to handle removing a tag
    const handleRemoveTag = (indexToRemove) => {
        setTags(tags.filter((_, index) => index !== indexToRemove));
    };


    // const options = [
    //     { value: 'option1', label: 'Option 1' },
    //     { value: 'option2', label: 'Option 2' },
    //     { value: 'option3', label: 'Option 3' },
    //     { value: 'option4', label: 'Option 4' },
    // ];


    // const handleChange1 = (selectedOptions) => {
    //     console.log(selectedOptions); // Returns an array of selected values
    // };


    // const chakraStyles = {
    //     control: (provided) => ({
    //         ...provided,
    //         border: '1px solid #E2E8F0',
    //         borderRadius: '8px',
    //         boxShadow: 'none',
    //         _hover: { borderColor: '#CBD5E0' },
    //     }),
    //     option: (provided, { isSelected }) => ({
    //         ...provided,
    //         backgroundColor: isSelected ? '#3182CE' : 'white',
    //         color: isSelected ? 'white' : 'black',
    //         _hover: { backgroundColor: '#E2E8F0' },
    //     }),
    // };

    // const colourOptions = [
    //     { value: 'red', label: 'Red' },
    //     { value: 'green', label: 'Green' },
    //     { value: 'blue', label: 'Blue' },
    //     { value: 'yellow', label: 'Yellow' },
    //     { value: 'pink', label: 'Pink' },
    //     { value: 'purple', label: 'Purple' },
    // ];


    const [tag, setTag] = useState([""]);

    const GetTags = async () => {
        try {

            const response = await mainservice.GetAllTags();
            setTag(response.data);
            console.log(response);

        } catch (error) {
            console.error('Error fetching product:', error);
        }
    };

    const [newTag, setNewTag] = useState('');
    const handleInputChangeTag = (event) => {
        setNewTag(event.target.value);
        console.log(newTag);

    };
    const [relatedTags, setRelatedTags] = useState([]); // State for related tags

    const handleAddNewTag = async () => {
        console.log('New Tag:', newTag);
        // const response = await mainservice.GetAllTags();
        // setTag(response.data);

        // Fetch related tags for the newly added tag
        const relatedTagsResponse = await mainservice.GetRelatedTags(newTag);
        setRelatedTags(relatedTagsResponse.data);
        setNewTag(''); // Clear input after adding
        onClose(); // Close the modal
    };

    const handleSavePost = async () => {
        const res = await mainservice.PostTag();

    }




    // // Dynamically import ReactQuill with no SSR
    // const ReactQuill = dynamic(() => import('react-quill'), { ssr: false });

    // const [editorValue, setEditorValue] = useState('');

    // Dynamically import ReactQuill with React.lazy
    const [isClient, setIsClient] = useState(false);



    useEffect(() => {
        
        GetTags();
        setIsClient(true);
    }, []);

    return (
        <div style={{ padding: '20px' }}>
            <h1 style={{ color: '#1A202C', textAlign: 'center', fontSize: '5vw', fontSize: '50px' }}>CREATE YOUR BLOG</h1><br />
            <form>
                <Grid templateColumns={{ base: '1fr', sm: 'repeat(6, 1fr)', }} gap="6" autoFlow="row">
                    <GridItem colSpan={1}>
                        <label>
                            Title : </label>
                    </GridItem>
                    <GridItem colSpan={5}>
                        <input
                            type="text"
                            name="title"
                            value={formData.title}
                            onChange={handleInputChange}
                            maxLength={100} // Set a fixed character limit
                            style={{ width: '100%', padding: '10px', marginTop: '5px' }}
                        />
                    </GridItem>
                </Grid>
                <br />
                <Grid
                    templateColumns={{
                        base: '1fr',
                        sm: 'repeat(6, 1fr)',
                    }}
                    gap="6"
                    autoFlow="row">
                    <GridItem colSpan={1}>
                        <label>
                            Short description : </label>
                    </GridItem>
                    <GridItem colSpan={5}>
                        <input
                            type="text"
                            name="metaDescription"
                            value={formData.metaDescription}
                            onChange={handleInputChange}
                            maxLength={100} // Set a fixed character limit
                            style={{ width: '100%', padding: '10px', marginTop: '5px' }}
                        />
                    </GridItem>
                </Grid><br />
                <Grid
                    templateColumns={{
                        base: '1fr',          // 1 column for small screens
                        sm: 'repeat(6, 1fr)', // 6 columns for sm and up
                    }}
                    gap="6"
                    autoFlow="row"
                >
                    <GridItem colSpan={1}>
                        <label>
                            Featured Image URL :</label>
                    </GridItem>
                    <GridItem colSpan={3}>
                        <input
                            type="file"
                            name="featuredImage"
                            onChange={handleImageChange}
                            style={{ width: '100%', padding: '10px', marginTop: '5px' }}
                        />
                        {imagePreview ? <img src={imagePreview} /> : null}
                    </GridItem>
                </Grid><br />



                <Grid
                    templateColumns={{
                        base: '1fr',          // 1 column for small screens
                        sm: 'repeat(6, 1fr)', // 6 columns for sm and up
                    }}
                    gap="6"
                    autoFlow="row"
                >
                    <GridItem colSpan={{ base: 1, sm: 1 }}>
                        <label>
                            Status :</label>
                    </GridItem>
                    <GridItem colSpan={{ base: 1, sm: 2 }}>
                        <select
                            name="status"
                            value={formData.status}
                            onChange={handleInputChange}
                            // maxLength={50} // Set a fixed character limit
                            style={{ width: '100%', padding: '10px', marginTop: '5px' }}
                        >
                            <option value="draft">Draft</option>
                            <option value="published">Published</option>
                            <option value="archived">Archived</option>
                        </select>
                    </GridItem>

                    <GridItem colSpan={{ base: 1, sm: 1 }}>
                        <label>
                            category :</label>
                    </GridItem>
                    <GridItem colSpan={{ base: 1, sm: 2 }}>
                        <select
                            name="categories"
                            value={formData.categories}
                            onChange={handleInputChange}
                            maxLength={50} // Set a fixed character limit
                            style={{ width: '100%', padding: '10px', marginTop: '5px' }}
                        >
                            <option value="draft">category 1</option>
                            <option value="published">category 2</option>
                            <option value="archived">category 3</option>
                        </select>
                    </GridItem>
                </Grid>




                <br />
                <Grid
                    templateColumns={{
                        base: '1fr',          // 1 column for small screens
                        sm: 'repeat(6, 1fr)', // 6 columns for sm and up
                    }} gap="6"
                    autoFlow="row"
                >
                    <GridItem colSpan={{ base: 1, sm: 1 }}>
                        <label>
                            tags :

                        </label>
                        {/* <Button size={['sm']} leftIcon={<AddIcon />} colorScheme="teal" variant="solid"></Button> */}
                        <Button onClick={onOpen} leftIcon={<AddIcon />} colorScheme="teal" variant="solid"></Button>

                        <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                                {/* <ModalHeader>Modal Title</ModalHeader> */}
                                <ModalCloseButton />

                                <ModalBody>
                                    <FormControl>
                                        <FormLabel>Add New Tag</FormLabel>
                                        <Input onChange={handleInputChangeTag} placeholder='Add New Tag' />
                                    </FormControl>
                                </ModalBody>

                                <ModalFooter>
                                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                                        Close
                                    </Button>
                                    <Button variant='ghost' onClick={handleAddNewTag}>Add</Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                    </GridItem>
                    <GridItem colSpan={{ base: 1, sm: 2 }}>

                        <Select
                            defaultValue={[]}
                            isMulti
                            // name="tags"
                            name="tags"
                            // options={tag}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />

                    </GridItem>
                    {/* </Grid><br />
                <Grid templateColumns="repeat(6, 1fr)" gap="6"> */}
                    <GridItem colSpan={{ base: 1, sm: 1 }}>
                        <label>
                            Related post :</label>
                    </GridItem>
                    <GridItem colSpan={{ base: 1, sm: 2 }}>
                        <select
                            name="relatedPosts"
                            // value={formData.relatedPosts}
                            // onChange={handleInputChange}
                            maxLength={50} // Set a fixed character limit
                            style={{ width: '100%', padding: '10px', marginTop: '5px' }}
                        >
                            <option value="draft">post 1</option>
                            <option value="published">post 2</option>
                            <option value="archived">post 3</option>
                        </select>
                    </GridItem>
                </Grid>
                {/* <Grid templateColumns="repeat(6, 1fr)" gap="6">
                    <GridItem colSpan={1}>
                        <label>
                            Meta Title :</label>
                    </GridItem>
                    <GridItem colSpan={5}>
                        <input
                            type="text"
                            name="metaTitle"
                            // value={formData.metaTitle}
                            // onChange={handleInputChange}
                            style={{ width: '100%', padding: '10px', marginTop: '5px' }}
                            maxLength="60"
                        />
                    </GridItem>
                </Grid><br /> */}

                <br />
                {/* <Grid templateColumns="repeat(6, 1fr)" gap="6">
                    <GridItem colSpan={1}>
                        <label>
                            Meta Description :</label>
                    </GridItem>
                    <GridItem colSpan={5}>
                        <textarea
                            name="metaDescription"
                            // value={formData.metaDescription}
                            // onChange={handleInputChange}
                            style={{ width: '100%', padding: '10px', marginTop: '5px' }}
                            maxLength="100%"
                        />
                    </GridItem>
                </Grid> */}
                <br />
                <Grid
                    templateColumns={{
                        base: '1fr',          // 1 column for small screens
                        sm: 'repeat(6, 1fr)', // 6 columns for sm and up
                    }} gap="6"
                    autoFlow="row"
                >
                    <GridItem colSpan={1}>keywords :</GridItem>
                    <Wrap mb={4}>
                        {tags.map((tag, index) => (
                            <WrapItem key={index}>
                                <Tag size="lg" variant="solid" colorScheme="blue">
                                    <TagLabel>{tag}</TagLabel>
                                    <TagCloseButton onClick={() => handleRemoveTag(index)} />
                                </Tag>
                            </WrapItem>
                        ))}
                    </Wrap>
                    <GridItem colSpan={3}>
                        <input
                            type="text"
                            name="keywords"
                            style={{ width: '100%', padding: '10px', marginTop: '5px' }}
                            placeholder="Type and press Enter"
                            value={inputValue} // Bind input value to state
                            onChange={(e) => setInputValue(e.target.value)} // Update state on input
                            onKeyDown={handleAddTag} // Add tag on Enter key press
                        />
                    </GridItem>
                </Grid><br />
                <div style={{ marginBottom: '15px' }}>
                    <label>
                        Enable Comments:
                        <input
                            type="checkbox"
                            name="commentsEnabled"
                            checked={formData.commentsEnabled}
                            onChange={(e) =>
                                setFormData({ ...formData, commentsEnabled: e.target.checked })
                            }
                            style={{ marginLeft: '10px' }}
                        />
                    </label>
                </div>
                <Suspense fallback={<div>Loading...</div>}>
                <div style={{ marginBottom: '15px', color: 'black' }}>
                    <label>Content:</label>
                    <ReactQuill
                        theme="snow"
                        value={formData.content}
                        onChange={handleQuillChange}

                        // value={editorValue}
                        // onChange={setEditorValue}
                        style={{ height: '300px', marginTop: '10px', color: 'black' }}
                    />
                </div>
                <br /><br /><br />
                <button
                    type="button"
                    onClick={handleSave}
                    style={{
                        padding: '10px 20px',
                        backgroundColor: '#007BFF',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }}
                >
                    Save Blog
                </button>
                </Suspense>
            </form>
        </div>
    )
}

export default Blog