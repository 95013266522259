import React from "react";
import Home from "../Pages/Home";
import CMS from "../Pages/CMS";
import Dashboard from "../Pages/dashboard";
import Blog from "../Pages/Blog";
import Ourblogs from "../Pages/Ourblogs";


const protectedRoutes = [
  { path: "/website", element: <Home /> },
  { path: "/cms", element: <CMS /> },
  { path: "/BlogEditor", element: <Blog /> },
  { path: "/Blogs", element: <Ourblogs /> }
];

export default protectedRoutes;
