import React, { useState, useRef, useEffect } from "react";

import {
  Image,
  Flex,
  Box,
  Button,
  Menu,
  MenuItem,
  MenuButton,
  IconButton,
  MenuList,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { PhoneIcon, HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import mainservice from "../../Services/mainservice";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import ReactCountryFlag from "react-country-flag";
import logo from "../../assets/img/logo.png";

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    lg: false,
  });

  const [small, setSmall] = useState(false);

  useEffect(() => {
    const handleScroll = () => setSmall(window.scrollY > 10);
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [country, setCountry] = useState([]);
  const GetCountry = async () => {
    try {
      const CountryData = await mainservice.GetAllCountry();
      setCountry(CountryData.data);
      console.log(CountryData.data);
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
  };

  useEffect(() => {
    GetCountry();
  }, []);

  const studyAbroadMenu = country.filter(
    (product) => product.Category === "Study Abroad"
  );
  const studyMBBSMenu = country.filter(
    (product) => product.Category === "MBBS"
  );
  const groupedItems = [];
  for (let i = 0; i < studyAbroadMenu.length; i += 5) {
    groupedItems.push(studyAbroadMenu.slice(i, i + 5));
  }
  const groupedItems2 = [];
  for (let i = 0; i < studyMBBSMenu.length; i += 5) {
    groupedItems2.push(studyMBBSMenu.slice(i, i + 5));
  }

  const [abroadmenu, setAbroadmenu] = useState(false);
  const menuRef = useRef(null);

  const closeMenu = () => setAbroadmenu(false);
  const toggleMenu = () => setAbroadmenu((prev) => !prev);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [mbbsmenu, setMBBSmenu] = useState(false);

  const closeMenu2 = () => setMBBSmenu(false);
  const toggleMenu2 = () => setMBBSmenu((prev) => !prev);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu2();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const navigate = useNavigate();

  return (

    <>


      {/* scroll */}
      {small ? (
        <Box className="header-scroll" h="80px" p="4">
          <Flex alignItems="center" justifyContent="space-between">
            <Box ml={2}>
              <Image
                onClick={() => {
                  navigate("/");
                }}
                w="180px"
                src={logo}
                alt="canbridgeLOGO"
              />
            </Box>
            {!isMobile ? (
              <Box color={"black"}>
                <Flex justifyContent={"space-between"}>
                  <Menu>
                    <Box p="2" ml="2">
                      <MenuButton
                        p={0}
                        onClick={() => {
                          navigate("/");
                        }}
                        m={0}
                        h="100%"
                        as={Button}
                        colorScheme="black"
                        variant="link"
                        width="full"
                        _focus={{ boxShadow: "none" }}
                        _hover={{ textDecoration: "underline" }}
                        to="/"
                      >
                        Home
                      </MenuButton>
                    </Box>
                    <Box p="2" ml="2">
                      <MenuButton
                        p={0}
                        onClick={() => {
                          navigate("/aboutus");
                        }}
                        m={0}
                        h="100%"
                        as={Button}
                        colorScheme="black"
                        variant="link"
                        width="full"
                        _focus={{ boxShadow: "none" }}
                        _hover={{ textDecoration: "underline" }}
                        to="/"
                      >
                        About Us
                      </MenuButton>
                    </Box>
                    <Box p="2" ml="2">
                      <>
                        <MenuButton
                          onClick={toggleMenu}
                          p={0}
                          m={0}
                          h="100%"
                          as={Button}
                          colorScheme="black"
                          variant="link"
                          width="full"
                          _focus={{ boxShadow: "none" }}
                          _hover={{ textDecoration: "underline" }}
                        >
                          Study Abroad
                        </MenuButton>
                        {abroadmenu && (
                          <Flex
                            ref={menuRef}
                            direction="row"
                            wrap="wrap"
                            mt={4}
                            position="absolute"
                            bg="white"
                            boxShadow="md"
                            p={4}
                            borderRadius="md"
                            maxWidth="90%" // Adjust for responsiveness
                            justifyContent="center"
                            // left="50%"
                            transform="translateX(-50%)"
                            zIndex={1}
                          >
                            {groupedItems.map((group, index) => (
                              <Box key={index} width="200px" p={4}>
                                {group.map((item, idx) => (
                                  <MenuItem
                                    onClick={() => {
                                      navigate(`/opportunity/study in ${item.Country}`);
                                      closeMenu();
                                    }}
                                    key={idx}
                                    tabIndex={0} // Improve keyboard accessibility
                                    aria-label={`Navigate to product in ${item.Country}`}
                                  >
                                    <ReactCountryFlag
                                      countryCode={item.CountryCode}
                                      svg
                                      aria-label={item.Country}
                                      style={{
                                        width: "1.5em",
                                        height: "1.5em",
                                      }} // Adjust size if needed
                                    />
                                    &nbsp;&nbsp;{item.Country}
                                  </MenuItem>
                                ))}
                              </Box>
                            ))}
                          </Flex>
                        )}
                      </>
                    </Box>{" "}
                  </Menu>
                  <Menu>
                    {" "}
                    <Box p="2" ml="2">
                      <>
                        <MenuButton
                          onClick={toggleMenu2}
                          p={0}
                          m={0}
                          h="100%"
                          as={Button}
                          colorScheme="black"
                          variant="link"
                          width="full"
                          _focus={{ boxShadow: "none" }}
                          _hover={{ textDecoration: "underline" }}
                        >
                          MBBS
                        </MenuButton>
                        {mbbsmenu && (
                          <Flex
                            ref={menuRef}
                            direction="row"
                            wrap="wrap"
                            mt={4}
                            position="absolute"
                            bg="white"
                            boxShadow="md"
                            p={4}
                            borderRadius="md"
                            // width="100%"
                            justifyContent="center"
                            // left="50%"
                            transform="translateX(-50%)"
                            zIndex={1}
                          >
                            {groupedItems2.map((group, index) => (
                              <Box key={index} width="200px" p={4}>
                                {group.map((item, idx) => (
                                  <MenuItem
                                    onClick={() => {
                                      navigate(`/opportunity/mbbs in ${item.Country}`);
                                      closeMenu();
                                    }}
                                    key={idx}
                                  >
                                    <ReactCountryFlag
                                      countryCode={item.CountryCode}
                                      svg
                                    />
                                    &nbsp;&nbsp;{item.Country}
                                  </MenuItem>
                                ))}
                              </Box>
                            ))}
                          </Flex>
                        )}
                      </>
                    </Box>
                    <Box p="2" ml="2">
                      <MenuButton
                        p={0}
                        onClick={() => {
                          navigate("/");
                        }}
                        m={0}
                        h="100%"
                        as={Button}
                        colorScheme="black"
                        variant="link"
                        width="full"
                        _focus={{ boxShadow: "none" }}
                        _hover={{ textDecoration: "underline" }}
                        to="/"
                      >
                        Emigration
                      </MenuButton>
                    </Box>
                    <Box p="2" ml="2">
                      <MenuButton
                        p={0}
                        onClick={() => {
                          navigate("/");
                        }}
                        m={0}
                        h="100%"
                        as={Button}
                        colorScheme="black"
                        variant="link"
                        width="full"
                        _focus={{ boxShadow: "none" }}
                        _hover={{ textDecoration: "underline" }}
                        to="/"
                      >
                        Other Services
                      </MenuButton>
                    </Box>
                  </Menu>

                  <Button
                    as="a"
                    href="tel:+917777977582"
                    color={"white"}
                    leftIcon={<PhoneIcon />}
                    ml="2"
                    bg={"#4831D4"}
                    _hover={{
                      bg: "#3FD1FF",
                      color: "#202023",
                      transform: "scale(1.05)",
                      boxShadow: "lg",
                    }}
                  >
                    Enquire Now
                  </Button>
                </Flex>
              </Box>
            ) : (
              <Box>
                <Flex alignItems="center" justifyContent="space-between">
                  <Menu isOpen={isOpen}>
                    <MenuButton
                      bg={"#white"}
                      as={IconButton}
                      icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                      variant="outline"
                      color="#4831D4"
                      onClick={isOpen ? onClose : onOpen}
                    />
                    <MenuList>
                      <MenuItem as={Link} to="/" onClick={onClose}>
                        Home
                      </MenuItem>
                      <MenuItem as={Link} to="/aboutus" onClick={onClose}>
                        About Us
                      </MenuItem>
                      <Accordion defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                          <h2>
                            <AccordionButton>
                              <Box as="span" flex="1" textAlign="left">
                                Study Abroad
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            {studyAbroadMenu.map((item, index) => (
                              <MenuItem
                                onClick={() => {
                                  navigate(`/opportunity/study in ${item.Country}`);
                                  onClose();
                                }}
                                key={index}
                              >
                                <ReactCountryFlag
                                  countryCode={item.CountryCode}
                                  svg
                                />
                                &nbsp;&nbsp;{item.Country}
                              </MenuItem>
                            ))}
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton>
                              <Box as="span" flex="1" textAlign="left">
                                MBBS
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            {studyMBBSMenu.map((item, index) => (
                              <MenuItem
                                onClick={() => {
                                  navigate(`/opportunity/mbbs in ${item.Country}`);
                                  onClose();
                                }}
                                key={index}
                              >
                                <ReactCountryFlag
                                  countryCode={item.CountryCode}
                                  svg
                                />
                                &nbsp;&nbsp;{item.Country}
                              </MenuItem>
                            ))}
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                      <MenuItem as={Link} to="/" onClick={onClose}>
                        Emigration
                      </MenuItem>
                      <MenuItem as={Link} to="/" onClick={onClose}>
                        Other Services
                      </MenuItem>
                      <Button
                        as="a"
                        href="tel:+917777977582"
                        color={"white"}
                        leftIcon={<PhoneIcon />}
                        ml="2"
                        bg={"#4831D4"}
                        _hover={{
                          bg: "#3FD1FF",
                          color: "#202023",
                          transform: "scale(1.05)",
                          boxShadow: "lg",
                        }}
                      >
                        Enquire Now
                      </Button>
                    </MenuList>
                  </Menu>
                </Flex>
              </Box>
            )}
          </Flex>
        </Box>
      ) : (
        // Attached Header
        <Box className="header" h="80px" p="4">
          <Flex alignItems="center" justifyContent="space-between">
            <Box ml={2}>
              <Image
                w="180px"
                onClick={() => {
                  navigate("/");
                }}
                src={logo}
                alt="CanbridgeLOGO"
              />
            </Box>
            {!isMobile ? (
              <Box color={'white'}>
                <Flex justifyContent={'space-between'}>
                  <Menu>
                    <Box p='2' ml='2'>
                      <MenuButton p={0}
                        onClick={() => { navigate("/") }}
                        m={0}
                        h="100%"
                        as={Button}
                        colorScheme="black"
                        variant="link"
                        width="full"
                        _focus={{ boxShadow: 'none' }}
                        _hover={{ textDecoration: 'underline' }} to='/'>Home</MenuButton></Box>
                    <Box p='2' ml='2'><MenuButton p={0}
                      onClick={() => { navigate("/aboutus") }}
                      m={0}
                      h="100%"
                      as={Button}
                      colorScheme="black"
                      variant="link"
                      width="full"
                      _focus={{ boxShadow: 'none' }}
                      _hover={{ textDecoration: 'underline' }} to='/'>About Us</MenuButton></Box>
                    <Box p='2' ml='2'>

                      <>
                        <MenuButton
                          onClick={toggleMenu}
                          p={0}
                          m={0}
                          h="100%"
                          as={Button}
                          colorScheme="black"
                          variant="link"
                          width="full"
                          _focus={{ boxShadow: 'none' }}
                          _hover={{ textDecoration: 'underline' }}
                        >
                          Study Abroad
                        </MenuButton>
                        {abroadmenu && (
                          <Flex
                            ref={menuRef}
                            direction="row"
                            wrap="wrap"
                            mt={4}
                            position="absolute"
                            bg="white"
                            boxShadow="md"
                            p={4}
                            borderRadius="md"
                            // width="100%"
                            justifyContent="center"
                            // left="50%"
                            transform="translateX(-50%)"
                            zIndex={1}
                            color={'black'}
                          >
                            {groupedItems.map((group, index) => (
                              <Box key={index} width="200px" p={4}>
                                {group.map((item, idx) => (
                                  <MenuItem
                                    onClick={() => {
                                      navigate(`/opportunity/study in ${item.Country}`);
                                      closeMenu();
                                    }}
                                    key={idx}
                                  >
                                    <ReactCountryFlag countryCode={item.CountryCode} svg />
                                    &nbsp;&nbsp;{item.Country}
                                  </MenuItem>
                                ))}
                              </Box>
                            ))}
                          </Flex>
                        )}
                      </>

                    </Box> </Menu>
                  <Menu>   <Box p='2' ml='2'>
                    <>
                      <MenuButton
                        onClick={toggleMenu2}
                        p={0}
                        m={0}
                        h="100%"
                        as={Button}
                        colorScheme="black"
                        variant="link"
                        width="full"
                        _focus={{ boxShadow: 'none' }}
                        _hover={{ textDecoration: 'underline' }}
                      >
                        MBBS
                      </MenuButton>
                      {mbbsmenu && (
                        <Flex
                          ref={menuRef}
                          direction="row"
                          wrap="wrap"
                          mt={4}
                          position="absolute"
                          bg="white"
                          boxShadow="md"
                          p={4}
                          borderRadius="md"
                          // width="100%"
                          justifyContent="center"
                          // left="50%"
                          transform="translateX(-50%)"
                          zIndex={1}
                          color={'black'}
                        >
                          {groupedItems2.map((group, index) => (
                            <Box key={index} width="200px" p={4}>
                              {group.map((item, idx) => (
                                <MenuItem
                                  onClick={() => {
                                    navigate(`/opportunity/mbbs in ${item.Country}`);
                                    closeMenu();
                                  }}
                                  key={idx}
                                >
                                  <ReactCountryFlag countryCode={item.CountryCode} svg />
                                  &nbsp;&nbsp;{item.Country}
                                </MenuItem>
                              ))}
                            </Box>
                          ))}
                        </Flex>
                      )}
                    </>
                  </Box>
                    <Box p='2' ml='2'><MenuButton p={0}
                      onClick={() => { navigate("/") }}
                      m={0}
                      h="100%"
                      as={Button}
                      colorScheme="black"
                      variant="link"
                      width="full"
                      _focus={{ boxShadow: 'none' }}
                      _hover={{ textDecoration: 'underline' }} to='/'>Emigration</MenuButton></Box>
                    <Box p='2' ml='2'><MenuButton p={0}
                      onClick={() => { navigate("/") }}
                      m={0}
                      h="100%"
                      as={Button}
                      colorScheme="black"
                      variant="link"
                      width="full"
                      _focus={{ boxShadow: 'none' }}
                      _hover={{ textDecoration: 'underline' }} to='/'>Other Services</MenuButton></Box></Menu>

                  <Button
                    as="a"
                    href="tel:+917777977582"
                    color={'white'}
                    leftIcon={<PhoneIcon />}
                    ml='2'
                    bg={'#4831D4'}
                    _hover={{
                      bg: "#3FD1FF",
                      color: '#202023',
                      transform: "scale(1.05)",
                      boxShadow: "lg",
                    }}
                  >
                    Enquire Now
                  </Button>
                </Flex>
              </Box>
            ) : (
              <Box>
                <Flex alignItems="center" justifyContent="space-between">
                  <Menu isOpen={isOpen}>
                    <MenuButton
                      // bg={'#white'}
                      as={IconButton}
                      icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                      variant="outline"
                      color="white"
                      _active={{ color: "black" }}
                      onClick={isOpen ? onClose : onOpen}
                    />
                    <MenuList>
                      <MenuItem as={Link} to="/" onClick={onClose}>Home</MenuItem>
                      <MenuItem as={Link} to="/aboutus" onClick={onClose}>About Us</MenuItem>
                      <Accordion allowMultiple>
                        <AccordionItem>
                          <h2>
                            <AccordionButton>
                              <Box as='span' flex='1' textAlign='left'>
                                Study Abroad
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            {studyAbroadMenu.map((item, index) => (
                              <MenuItem
                                onClick={() => {
                                  navigate(`/opportunity/study in ${item.Country}`);
                                  onClose();
                                }}
                                key={index}
                              >
                                <ReactCountryFlag countryCode={item.CountryCode} svg />
                                &nbsp;&nbsp;{item.Country}
                              </MenuItem>
                            ))}
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton>
                              <Box as='span' flex='1' textAlign='left'>
                                MBBS
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            {studyMBBSMenu.map((item, index) => (
                              <MenuItem
                                onClick={() => {
                                  navigate(`/opportunity/mbbs in ${item.Country}`);
                                  onClose();
                                }}
                                key={index}
                              >
                                <ReactCountryFlag countryCode={item.CountryCode} svg />
                                &nbsp;&nbsp;{item.Country}
                              </MenuItem>
                            ))}
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                      <MenuItem as={Link} to="/product" onClick={onClose}>Emigration</MenuItem>
                      <MenuItem as={Link} to="/product" onClick={onClose}>Other Services</MenuItem>
                      <Button
                        as="a"
                        href="tel:+917777977582"
                        color={'white'}
                        leftIcon={<PhoneIcon />}
                        ml='2'
                        bg={'#4831D4'}
                        _hover={{
                          bg: "#3FD1FF",
                          color: '#202023',
                          transform: "scale(1.05)",
                          boxShadow: "lg",
                        }}
                      >
                        Enquire Now
                      </Button>
                    </MenuList>
                  </Menu>
                </Flex>
              </Box>
            )}
          </Flex>
        </Box>
      )}
    </>
  );
};

export default Header;
