import React, { useEffect, useState } from "react";
import {
  Image,
  Stack,
  Heading,
  Card,
  Flex,
  Box,
  Text,
  CardBody,
  Wrap,
  WrapItem,
  Center,
  List,
  Square,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useDisclosure,
  Menu,
  MenuItem,
  MenuButton,
  IconButton,
  MenuList,
  useBreakpointValue,
  Avatar,
  Input,
  Textarea,
} from "@chakra-ui/react";
import Slider from "react-slick";
import abc from "../assets/img/abc.png";
import img1 from "../assets/img/img1.png";

import img2 from "../assets/img/img2.png";
import country from "../assets/img/country.png";
// Ensure you have appropriate CSS for .slider class if needed
import "./css/style.css";
import { wrap } from "framer-motion";
import {
  ArrowForwardIcon,
  PhoneIcon,
  HamburgerIcon,
  CloseIcon,
} from "@chakra-ui/icons";
import Header from "./component/Header";
import Footer from "./component/Footer";

import cro1 from "../assets/university icon/1.png";
import cro2 from "../assets/university icon/2.png";
import cro3 from "../assets/university icon/3.png";
import cro4 from "../assets/university icon/4.png";
import cro5 from "../assets/university icon/5.png";
import cro6 from "../assets/university icon/6.png";
import cro7 from "../assets/university icon/7.png";
import cro8 from "../assets/university icon/8.png";
import cro9 from "../assets/university icon/9.png";
import cro10 from "../assets/university icon/10.png";
import cro11 from "../assets/university icon/11.png";
import cro12 from "../assets/university icon/12.png";
import cro13 from "../assets/university icon/13.png";
import cro14 from "../assets/university icon/14.png";
import cro15 from "../assets/university icon/15.png";
import cro16 from "../assets/university icon/16.png";
import cro17 from "../assets/university icon/17.png";
import cro18 from "../assets/university icon/18.png";
import cro19 from "../assets/university icon/19.png";
import cro20 from "../assets/university icon/20.png";
import cro21 from "../assets/university icon/21.png";
import cro22 from "../assets/university icon/22.png";
import cro23 from "../assets/university icon/23.png";
import cro24 from "../assets/university icon/24.png";
import cro25 from "../assets/university icon/25.png";
import cro26 from "../assets/university icon/26.png";
import cro27 from "../assets/university icon/27.png";
import cro28 from "../assets/university icon/28.png";
import cro29 from "../assets/university icon/29.png";
import imgcont from "../assets/img/contact.png";
import simg from "../assets/img/services.png";
import aboimg from "../assets/img/about.png";
import trend1 from "../assets/img/trend1.jpg";
import trend2 from "../assets/img/trend2.jpg";
import trend3 from "../assets/img/trend3.jpg";
import mainservice from "../Services/mainservice";
import { Helmet } from "react-helmet";

import { useToast } from "@chakra-ui/react";

const Home = () => {
  let settings = {
    className: "",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };
  const multisettings = {
    className: "slider variable-width",
    dots: false,
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  const settings3 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings4 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  const testimonials = [
    {
      name: "Amal Vs",
      testimonial:
        "Im excited to share how fantastic my experience with Can bridge Global was for my education plan in France. From the day I registered to the arrival of my visa, their service was exceptional. The staff was not only cooperative but also incredibly polite and professional throughout the process. It was a truly rewarding experience, and I’m so grateful for all their help.",
    },
    {
      name: "Reshma Ramesh",
      testimonial:
        "Can bridge made my dream of studying abroad in Germany a reality. From our first meeting to guiding me through the intricate visa application process, their support was invaluable and made every step of the journey unforgettable. Thank you, Can bridge, for your outstanding assistance! ",
    },
    {
      name: "Viji V",
      testimonial:
        "f you're thinking about studying in New Zealand, I highly recommend them. Their expertise and support made everything a lot smoother and less stressful for me. Now, I can focus on getting ready for my exciting new adventure in New Zealand.",
    },
    {
      name: "Anto Paul",
      testimonial:
        "A remarkable experience from start to finish.I'm incredibly thankful for Can bridge's amazing support in helping me achieve my dream of studying in Poland. From our first meeting to navigating the complex visa application process, Can bridge was there every step of the way, providing invaluable guidance and support. Thank you, Can bridge!“",
    },
  ];

  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First Name is required";
    if (!formData.lastName) newErrors.lastName = "Last Name is required";
    if (!formData.phone) newErrors.phone = "Phone Number is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.message) newErrors.message = "Message is required";
    return newErrors;
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});
    await SentForm(formData);
  };

  const SentForm = async (form) => {
    setIsLoading(true);
    try {
      const res = await mainservice.ContactForm(form);

      switch (res.status) {
        case 201:
          toast({
            title: "Form submitted successfully!",
            status: "success",
            isClosable: true,
          });
          setFormData({
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            message: "",
          }); // Clear the form
          break;
        case 400:
          toast({
            title: "Bad request. Please check your input.",
            status: "error",
            isClosable: true,
          });
          break;
        case 500:
          toast({
            title: "Server error. Please try again later.",
            status: "error",
            isClosable: true,
          });
          break;
        default:
          toast({
            title: "An unexpected error occurred.",
            status: "error",
            isClosable: true,
          });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast({
        title: "Network error. Please try again later.",
        status: "error",
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container">
      <Helmet>
        <title>Study Abroad with Canbridge Global Education Consultant</title>
        <meta
          name="description"
          content="Discover top study abroad options with Trivandrum's trusted Canbridge consultancy. Achieve your overseas education dreams with our comprehensive services."
        />
        <link rel="canonical" href="https://www.canbridge.in" />
        <meta
          property="og:title"
          content="The Canbridge Global | Study Abroad Consultancy | Trivandrum"
        />
        <meta
          property="og:description"
          content="Explore top study abroad opportunities with Trivandrum's trusted consultancy.Canbridge provide End-to-end solutions to help you achieve your education dreams overseas."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content='../assets/img/abc.png' />
        <meta property="og:url" content="https://www.canbridge.in" />
        <meta property="og:locale" content="en_US" />
        <meta
          name="keywords"
          content="study abroad consultancy in trivandrum,abroad education consultants,study abroad,overseas education consultants,canbridge"
        ></meta>
      </Helmet>
      {/* Header */}
      <Header />
      {/* Slider */}

      <Box className="slider-container">
        <Slider {...settings}>
          <div>
            <Flex
              wrap={{ base: "wrap", md: "nowrap" }}
              direction={{ base: "column", md: "row" }}
            >
              <Box p={"10%"} w={{ base: "100%", md: "50%" }}>
                <Stack spacing={6}>
                  <Heading as="h2" size="xl">
                    Study Abroad
                  </Heading>
                  <Heading as="h2" size="3xl">
                    Career Coaching Services: Expert Guidance for Success
                  </Heading>

                  <Heading as="h5" size="sm">
                    Enhance your carrier with our expert guidance, resources,
                    and support. Discover career development courses, career
                    growth strategies, and career enhancement programs to unlock
                    your full potential.{" "}
                  </Heading>
                  <Square justifyContent={"start"}>
                    <Button
                      as="a"
                      href="tel:+917777977582"
                      color={"white"}
                      leftIcon={<PhoneIcon />}
                      bg={"#4831D4"}
                      _hover={{
                        bg: "#3FD1FF",
                        color: "#202023",
                        transform: "scale(1.05)",
                        boxShadow: "lg",
                      }}
                    >
                      Enquire Now
                    </Button>{" "}
                  </Square>
                </Stack>
              </Box>
              <Box w={{ base: "100%", md: "50%" }}>
                <Image
                  src={abc} // Use the imported image here
                  alt="Canbridge Study Abroad Consultancy" // Provide a meaningful alt text
                  borderRadius="md" // Optional: Adds rounded corners to the image
                  objectFit="cover" // Ensures the image covers the box area
                  boxSize="100%" // Ensures the image takes up the full size of the Box
                />
              </Box>
            </Flex>
          </div>
          <div>
            <Flex
              wrap={{ base: "wrap", md: "nowrap" }}
              direction={{ base: "column", md: "row" }}
            >
              <Box p={"10%"} w={{ base: "100%", md: "50%" }}>
                <Stack spacing={6}>
                  <Heading as="h3" size="3xl">
                    Top-Ranked Universities Await You
                  </Heading>

                  <Heading as="h3" size="xl">
                    Your Gateway to World-Class Education
                  </Heading>

                  <Heading as="h5" size="sm">
                    Partner with Trivandrum's most trusted study abroad
                    consultants to gain access to top-ranked universities across
                    the globe. Our experienced team will help you navigate the
                    application process, ensuring you find the perfect fit for
                    your educational aspirations{" "}
                  </Heading>
                  <Square justifyContent={"start"}>
                    <Button
                      as="a"
                      href="tel:+917777977582"
                      color={"white"}
                      leftIcon={<PhoneIcon />}
                      bg={"#4831D4"}
                      _hover={{
                        bg: "#3FD1FF",
                        color: "#202023",
                        transform: "scale(1.05)",
                        boxShadow: "lg",
                      }}
                    >
                      Enquire Now
                    </Button>{" "}
                  </Square>
                </Stack>
              </Box>
              <Box w={{ base: "100%", md: "50%" }}>
                <Image
                  src={img1} // Use the imported image here
                  alt="Top Ranked Countries" // Provide a meaningful alt text
                  borderRadius="md" // Optional: Adds rounded corners to the image
                  objectFit="cover" // Ensures the image covers the box area
                  boxSize="100%" // Ensures the image takes up the full size of the Box
                />
              </Box>
            </Flex>
          </div>
          <div>
            <Flex
              wrap={{ base: "wrap", md: "nowrap" }}
              direction={{ base: "column", md: "row" }}
            >
              <Box p={"10%"} w={{ base: "100%", md: "50%" }}>
                <Stack spacing={6}>
                  <Heading as="h2" size="xl">
                    Leading Study Abroad Consultant
                  </Heading>
                  <Heading as="h2" size="3xl">
                    Explore Global Opportunities with Expert Guidance
                  </Heading>

                  <Heading as="h5" size="sm">
                    Discover a world of educational opportunities with
                    Trivandrum’s leading study abroad consultants. Whether
                    you're aiming for undergraduate or postgraduate studies, we
                    provide tailored solutions to match your goals with the best
                    global institutions.{" "}
                  </Heading>
                  <Square justifyContent={"start"}>
                    <Button
                      as="a"
                      href="tel:+917777977582"
                      color={"white"}
                      leftIcon={<PhoneIcon />}
                      bg={"#4831D4"}
                      _hover={{
                        bg: "#3FD1FF",
                        color: "#202023",
                        transform: "scale(1.05)",
                        boxShadow: "lg",
                      }}
                    >
                      Enquire Now
                    </Button>{" "}
                  </Square>
                </Stack>
              </Box>
              <Box w={{ base: "100%", md: "50%" }}>
                <Image
                  src={img2}
                  alt="Top Ranked Unvirsities" // Provide a meaningful alt text
                  borderRadius="md" // Optional: Adds rounded corners to the image
                  objectFit="cover" // Ensures the image covers the box area
                  boxSize="100%" // Ensures the image takes up the full size of the Box
                />
              </Box>
            </Flex>
          </div>
        </Slider>
      </Box>

      {/* stats */}
      <Center as="section" className="strength" p={{ base: "1vw", md: "10vw" }}>
        <Stack>
          <Center as="header">
            <Heading as="h2" textAlign={"center"}>
              Global Education Services
            </Heading>
          </Center>
          <Center as="p" textAlign={"center"}>
            Expert study abroad guidance and global education solutions.
          </Center>
          <Wrap as="article" mt={"3"}>
            <WrapItem className="strength-card">
              <Center w={["100vw", "100vw", "25vw"]} h="100%" bg="white">
                <Stack align={"center"} p={10}>
                  <Heading as="h2" size="xl">
                    800+
                  </Heading>
                  <Heading
                    as="h3"
                    size="md"
                    color="#4831D4"
                    textAlign={"center"}
                  >
                    Global University Tie Ups
                  </Heading>
                  <Text align={"center"}>
                    Unlock global opportunities with our extensive network of
                    global university tie-ups, offering study abroad programs
                    and international education solutions.
                  </Text>
                </Stack>
              </Center>
            </WrapItem>
            <WrapItem>
              <Center w={["100vw", "100vw", "25vw"]} h="100%" bg="white">
                <Stack align={"center"} p={10}>
                  <Heading as="h2" size="xl">
                    50K
                  </Heading>
                  <Heading as="h3" size="md" color="#4831D4">
                    Programs
                  </Heading>
                  <Text align={"center"}>
                    Explore top study abroad programs with expert guidance,
                    global education resources, and international degree
                    opportunities.
                  </Text>
                </Stack>
              </Center>
            </WrapItem>
            <WrapItem>
              <Center w={["100vw", "100vw", "25vw"]} h="100%" bg="white">
                <Stack align={"center"} p={10}>
                  <Heading as="h2" size="xl">
                    40+
                  </Heading>
                  <Heading as="h3" size="md" color="#4831D4">
                    Countries
                  </Heading>
                  <Text align={"center"}>
                    Explore top study abroad countries, international
                    universities, and global education opportunities for a
                    successful career.
                  </Text>
                </Stack>
              </Center>
            </WrapItem>
          </Wrap>
        </Stack>
      </Center>

      {/* Trending */}
      <div className="slider-container3">
        <Center>
          <Heading as="h2" textAlign={"center"}>
            Trending Now
          </Heading>{" "}
        </Center>
        <Center textAlign={"center"}>
          Discover the Trending Courses in Leading Study Destinations Worldwide
        </Center>
        <Slider {...settings3}>
          <Box w="100%" m={1} p={5} position="relative">
            <Box position="relative" w="100%" overflow="hidden">
              <Image w="100%" src={trend1} alt="Study in Italy" />
              {/* Overlay for dark background and button */}
              <Box
                position="absolute"
                top="0"
                left="0"
                w="100%"
                h="100%"
                bg="rgba(0, 0, 0, 0.6)"
                opacity="0"
                transition="opacity 0.3s ease-in-out"
                display="flex"
                alignItems="center"
                justifyContent="center"
                _hover={{ opacity: 1 }}
              >
                <Button
                  color={"white"}
                  leftIcon={<PhoneIcon />}
                  ml="2"
                  bg={"#4831D4"}
                  _hover={{
                    bg: "#3FD1FF",
                    color: "#202023",
                    transform: "scale(1.05)",
                    boxShadow: "lg",
                  }}
                >
                  Enquire Now
                </Button>
              </Box>
            </Box>
          </Box>
          <Box w="100%" m={1} p={5} position="relative">
            <Box position="relative" w="100%" overflow="hidden">
              <Image w="100%" src={trend2} alt="Study in Singapore" />
              {/* Overlay for dark background and button */}
              <Box
                position="absolute"
                top="0"
                left="0"
                w="100%"
                h="100%"
                bg="rgba(0, 0, 0, 0.6)"
                opacity="0"
                transition="opacity 0.3s ease-in-out"
                display="flex"
                alignItems="center"
                justifyContent="center"
                _hover={{ opacity: 1 }}
              >
                <Button
                  color={"white"}
                  leftIcon={<PhoneIcon />}
                  ml="2"
                  bg={"#4831D4"}
                  _hover={{
                    bg: "#3FD1FF",
                    color: "#202023",
                    transform: "scale(1.05)",
                    boxShadow: "lg",
                  }}
                >
                  Enquire Now
                </Button>
              </Box>
            </Box>
          </Box>
          <Box w="100%" m={1} p={5} position="relative">
            <Box position="relative" w="100%" overflow="hidden">
              <Image w="100%" src={trend3} alt="Study in Poland" />
              {/* Overlay for dark background and button */}
              <Box
                position="absolute"
                top="0"
                left="0"
                w="100%"
                h="100%"
                bg="rgba(0, 0, 0, 0.6)"
                opacity="0"
                transition="opacity 0.3s ease-in-out"
                display="flex"
                alignItems="center"
                justifyContent="center"
                _hover={{ opacity: 1 }}
              >
                <Button
                  color={"white"}
                  leftIcon={<PhoneIcon />}
                  ml="2"
                  bg={"#4831D4"}
                  _hover={{
                    bg: "#3FD1FF",
                    color: "#202023",
                    transform: "scale(1.05)",
                    boxShadow: "lg",
                  }}
                >
                  Enquire Now
                </Button>
              </Box>
            </Box>
          </Box>
        </Slider>
      </div>
      {/* Services */}
      <Box className="offer" color={"black"} p={{ base: "1vw", md: "10vw" }}>
        <Center>
          <Heading mt={{ base: "50px" }} textAlign={"center"}>
            {" "}
            Services and Offerings{" "}
          </Heading>
        </Center>
        <Center>
          <Text textAlign={"center"}>
            {" "}
            Your Gateway to Global Education and Opportunities{" "}
          </Text>{" "}
        </Center>

        <Flex direction={{ base: "column-reverse", md: "row" }}>
          {/* Left Side: Accordion */}
          <Box w={{ base: "100%", md: "50%" }} m={1} p={5}>
            <Accordion color={"black"} defaultIndex={[0]} allowMultiple>
              <AccordionItem bg={"white"} mb={2}>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    <Heading as="h2" size={"md"}>
                      Educational Consultancy
                    </Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  {" "}
                  <Box p="1">
                    Experience seamless international university admissions with
                    our Educational Consultancy services for Can bridge Global
                    Immigration. We provide expert guidance on study abroad
                    programs, program selection, and student visa assistance.
                    Our Educational Consultancy team understands the
                    complexities of global education and offers tailored support
                    for a smooth transition. From application management to
                    post-graduation support, we empower students to achieve
                    their global career goals. Trust our Educational Consultancy
                    services for Can bridge Global Immigration to make your
                    global education dreams a reality.{" "}
                  </Box>
                  <br />
                  {/* <Button
                                        as="a"
                                        href="tel:+917777977582"
                                        color={'white'} leftIcon={<PhoneIcon />} ml='2' bg={'#4831D4'} _hover={{
                                            bg: "#3FD1FF",
                                            color: '#202023',
                                            transform: "scale(1.05)",
                                            boxShadow: "lg",
                                        }}>Enquire Now</Button> */}
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem bg={"white"} mb={2}>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    <Heading as="h2" size={"md"}>
                      Study Abroad
                    </Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  {" "}
                  <Box p="1">
                    Embark on a life-changing Study Abroad experience with Can
                    bridge Global Immigration Services. Our expert consultants
                    guide you through international university admissions, study
                    abroad programs, and visa assistance for a successful global
                    career. With a deep understanding of global education
                    trends, we provide personalized support for seamless
                    transitions to top universities worldwide. Trust our Study
                    Abroad services for Can bridge Global Immigration to unlock
                    your future.{" "}
                  </Box>
                  <br />
                  {/* <Button 
                                       as="a"
                                       href="tel:+917777977582" color={'white'} leftIcon={<PhoneIcon />} ml='2' bg={'#4831D4'} _hover={{
                                        bg: "#3FD1FF",
                                        color: '#202023',
                                        transform: "scale(1.05)",
                                        boxShadow: "lg",
                                    }}>Enquire Now</Button> */}
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem bg={"white"} mb={2}>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    <Heading as="h2" size={"md"}>
                      Online Academy
                    </Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  {" "}
                  <Box p="1">
                    Experience seamless language proficiency with our Language
                    Training Online Academy services for Can bridge Global
                    Immigration. Our experts offer online language training
                    solutions for IELTS, German language, and other tests,
                    ensuring students receive the best guidance for
                    international university admissions. From language coaching
                    to visa assistance, we empower students to achieve their
                    global career goals.{" "}
                  </Box>
                  <br />
                  {/* <Button color={'white'} leftIcon={<PhoneIcon />} ml='2' bg={'#4831D4'} _hover={{
                                        bg: "#3FD1FF",
                                        color: '#202023',
                                        transform: "scale(1.05)",
                                        boxShadow: "lg",
                                    }}>Enquire Now</Button> */}
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem bg={"white"} mb={2}>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    <Heading as="h2" size={"md"}>
                      Emigration Assistance
                    </Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  {" "}
                  <Box p="1">
                    Streamline your migration journey with our Emigration
                    Assistance services for Cambridge Global Immigration. Our
                    expert consultants provide personalized guidance on visa
                    applications, immigration procedures, and destination
                    support to ensure a successful global relocation. With a
                    deep understanding of global immigration trends, we offer
                    comprehensive support for seamless transitions to new
                    destinations worldwide. Trust our Emigration Assistance
                    services for Cambridge Global Immigration to achieve your
                    global relocation aspirations.{" "}
                  </Box>
                  <br />
                  {/* <Button color={'white'} leftIcon={<PhoneIcon />} ml='2' bg={'#4831D4'} _hover={{
                                        bg: "#3FD1FF",
                                        color: '#202023',
                                        transform: "scale(1.05)",
                                        boxShadow: "lg",
                                    }}>Enquire Now</Button>
                                    <Button color={'white'} leftIcon={<PhoneIcon />} ml='2' bg={'#4831D4'} _hover={{
                                        bg: "#3FD1FF",
                                        color: '#202023',
                                        transform: "scale(1.05)",
                                        boxShadow: "lg",
                                    }}>Enquire Now</Button>
                                    <Button color={'white'} leftIcon={<PhoneIcon />} ml='2' bg={'#4831D4'} _hover={{
                                        bg: "#3FD1FF",
                                        color: '#202023',
                                        transform: "scale(1.05)",
                                        boxShadow: "lg",
                                    }}>Enquire Now</Button> */}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <br />
            <Button
              as="a"
              href="tel:+917777977582"
              color={"white"}
              leftIcon={<PhoneIcon />}
              ml="2"
              bg={"#4831D4"}
              _hover={{
                bg: "#3FD1FF",
                color: "#202023",
                transform: "scale(1.05)",
                boxShadow: "lg",
              }}
            >
              Enquire Now
            </Button>
          </Box>

          {/* Right Side: Image */}
          <Box w={{ base: "100%", md: "50%" }} p="5">
            <Image src={simg} alt="Canbridge Services" />
          </Box>
        </Flex>
      </Box>

      {/* Top Countries and University */}
      <Box className="nothing" p={{ base: "1vw", md: "10vw" }}>
        <Center>
          <Heading as="h2" textAlign={"center"}>
            {" "}
            Top Countries & Universities{" "}
          </Heading>
        </Center>
        <Flex direction={{ base: "column", md: "row" }} pt={10}>
          <Box w={{ base: "100%", md: "50%" }} m={1} p={5}>
            <Box>
              <Image src={country} alt="Canbridge global " />
            </Box>
          </Box>
          <Box w={{ base: "100%", md: "50%" }} m={1} p={5}>
            <Stack>
              <Heading as="h2" size={"md"}>
                Canbridge’s Multi Country Advantage
              </Heading>
              <Heading as="h3">
                Embark on a Global Learning Odyssey with Overseas Education
                Study Abroad
              </Heading>
              <Text as="paragraph">
                Join the global learning community with Overseas Education Study
                Abroad. Our expert consultants provide personalized guidance on
                study abroad programs, international university admissions, and
                visa assistance. Discover new cultures, gain valuable skills,
                and launch your global career with us.
              </Text>
              <div className="top-university">
                <Slider {...multisettings}>
                  <Box w="100" p={"2"}>
                    <Image h="90" src={cro1} alt="Canbridge global " />
                  </Box>
                  <Box w="100" p={"2"}>
                    <Image h="90" src={cro2} alt="Canbridge global " />
                  </Box>
                  <Box w="100" p={"2"}>
                    <Image
                      h="90"
                      src={cro3}
                      filter="invert(100%)"
                      alt="Canbridge global "
                    />
                  </Box>
                  <Box w="100" p={"2"}>
                    <Image h="90" src={cro4} alt="Canbridge global " />
                  </Box>
                  {/* <Box w='100' p={'2'}>
                                        <Image h='90' src={cro5} alt="Canbridge global " />
                                    </Box> */}
                  <Box w="100" p={"2"}>
                    <Image h="90" src={cro6} alt="Canbridge global " />
                  </Box>
                  <Box w="100" p={"2"}>
                    <Image h="90" src={cro7} alt="Canbridge global " />
                  </Box>
                  <Box w="100" p={"2"}>
                    <Image h="90" src={cro8} alt="Canbridge global " />
                  </Box>
                  <Box w="100" p={"2"}>
                    <Image h="90" src={cro9} alt="Canbridge global " />
                  </Box>
                  <Box w="100" p={"2"}>
                    <Image h="90" src={cro10} alt="Canbridge global " />
                  </Box>
                  <Box w="100" p={"2"}>
                    <Image h="90" src={cro11} alt="Canbridge global " />
                  </Box>
                  <Box w="100" p={"2"}>
                    <Image h="90" src={cro12} alt="Canbridge global " />
                  </Box>
                </Slider>
              </div>

              <div className="top-university">
                <Slider {...multisettings}>
                  <Box w="100" p={"2"}>
                    <Image h="90" src={cro24} alt="Canbridge global " />
                  </Box>
                  <Box w="100" p={"2"}>
                    <Image h="90" src={cro25} alt="Canbridge global " />
                  </Box>
                  <Box w="100" p={"2"}>
                    <Image h="90" src={cro26} alt="Canbridge global " />
                  </Box>
                  <Box w="100" p={"2"}>
                    <Image h="90" src={cro14} alt="Canbridge global " />
                  </Box>
                  <Box w="100" p={"2"}>
                    <Image h="90" src={cro15} alt="Canbridge global " />
                  </Box>
                  <Box w="100" p={"2"}>
                    <Image h="90" src={cro16} alt="Canbridge global " />
                  </Box>
                  <Box w="100" p={"2"}>
                    <Image
                      h="90"
                      src={cro17}
                      filter="invert(100%)"
                      alt="Canbridge global "
                    />
                  </Box>
                  <Box w="100" p={"2"}>
                    <Image
                      h="90"
                      src={cro18}
                      filter="invert(100%)"
                      alt="Canbridge global "
                    />
                  </Box>
                  <Box w="100" p={"2"}>
                    <Image h="90" src={cro19} alt="Canbridge global " />
                  </Box>
                  <Box w="100" p={"2"}>
                    <Image h="90" src={cro20} alt="Canbridge global " />
                  </Box>
                  <Box w="100" p={"2"}>
                    <Image h="90" src={cro21} alt="Canbridge global " />
                  </Box>
                  <Box w="100" p={"2"}>
                    <Image h="90" src={cro22} alt="Canbridge global " />
                  </Box>
                </Slider>
              </div>
            </Stack>
          </Box>
        </Flex>
      </Box>

      {/* About Us */}
      <Box
        as="section"
        className="offer"
        p={{ base: "1vw", md: "10vw" }}
        color="black"
      >
        <Flex direction={{ base: "column", md: "row" }} pt={10}>
          <Box as="article" w={{ base: "100%", md: "50%" }} m={1} p={10}>
            <Stack>
              <Heading as="h2" size="md">
                Unlock Global Education Opportunities
              </Heading>
              <Heading as="h3">
                About Canbridge Study Abroad - Your Trusted Study Abroad
                Consultant
              </Heading>
              <Text>
                At Canbridge Study Abroad Pvt. Ltd, we are passionate about
                empowering students to achieve their global education goals. As
                a leading study abroad consultant in India, we provide expert
                guidance on global education opportunities, connecting students
                with top universities worldwide. Our team of international
                education experts offers personalized support for students,
                colleges, and professionals, ensuring a successful study abroad
                experience. From application management to post-graduation
                support, we empower individuals to succeed globally, fostering a
                community of global learners and thinkers.
              </Text>
              <br />
              <Heading as="h2" size="md">
                Why Canbridge study abroad Consultancy?
              </Heading>
              <Text>
                Choose Can bridge Study Abroad Consultancy for a hassle-free
                study abroad experience. We offer expert guidance, personalized
                support, and competitive service charges, making your global
                education dreams a reality.
              </Text>
            </Stack>
          </Box>
          <Box as="aside" w={{ base: "100%", md: "50%" }} m={1} p={5}>
            <Image
              src={aboimg}
              alt="Canbridge Study Abroad consultants discussing with a student"
            />
          </Box>
        </Flex>
      </Box>

      {/* Testimonial */}
      <Box p={{ base: "1vw", md: "10vw" }} className="testimonial">
        <Text
          textAlign="center"
          fontSize={{ base: "2xl", md: "3xl" }}
          fontWeight="bold"
          mb={{ base: 6, md: 8 }}
        >
          What Our Clients Say
        </Text>
        <Slider {...settings4}>
          {testimonials.map((testimonial, index) => (
            <Box key={index} px={{ base: 2, md: 4 }} pb={2}>
              <Box
                bg="#f0f0f0"
                p={{ base: 6, md: 8 }}
                borderRadius="lg"
                shadow="lg"
                maxW={{ base: "sm", md: "md" }}
                mx="auto"
              >
                <Flex align="center">
                  {/* <Avatar src={testimonial.image} alt={testimonial.name} size={{ base: "md", md: "lg" }} /> */}
                  <Stack spacing={1} ml={4}>
                    <Text fontWeight="bold" fontSize={{ base: "md", md: "lg" }}>
                      {testimonial.name}
                    </Text>
                    {/* <Text color="gray.600" fontSize={{ base: "sm", md: "md" }}>
                                            {testimonial.role}
                                        </Text> */}
                  </Stack>
                </Flex>
                <Text
                  mt={4}
                  color="gray.800"
                  fontSize={{ base: "sm", md: "md" }}
                >
                  "{testimonial.testimonial}"
                </Text>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>

      {/* Contact Us */}
      <Box className="offer" p={{ base: "1vw", md: "10vw" }} color={"black"}>
        <Flex
          direction={{ base: "column", md: "row" }}
          pt={10}
          align={"center"}
        >
          <Box w={{ base: "100%", md: "50%" }} m={1} p={5}>
            <Image src={imgcont} alt="Contact Us" />{" "}
            {/* Replace with your image */}
          </Box>
          <Box w={{ base: "100%", md: "50%" }} m={1} p={10}>
            <Stack>
              <Heading as="h3" color={"black"} size={"md"}>
                CONTACT US
              </Heading>
              <Heading color={"black"}>We’d Love to Hear from You!</Heading>
              <br />
              <Input
                variant="filled"
                placeholder="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={onChangeHandler}
                isInvalid={!!errors.firstName}
              />
              {errors.firstName && (
                <Box color="red.500">{errors.firstName}</Box>
              )}
              <Input
                variant="filled"
                placeholder="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={onChangeHandler}
                isInvalid={!!errors.lastName}
              />
              {errors.lastName && <Box color="red.500">{errors.lastName}</Box>}
              <Input
                variant="filled"
                placeholder="Phone Number"
                name="phone"
                value={formData.phone}
                onChange={onChangeHandler}
                isInvalid={!!errors.phone}
              />
              {errors.phone && <Box color="red.500">{errors.phone}</Box>}
              <Input
                variant="filled"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={onChangeHandler}
                isInvalid={!!errors.email}
              />
              {errors.email && <Box color="red.500">{errors.email}</Box>}
              <Textarea
                variant="filled"
                placeholder="Your Message Here"
                name="message"
                value={formData.message}
                onChange={onChangeHandler}
                isInvalid={!!errors.message}
              />
              {errors.message && <Box color="red.500">{errors.message}</Box>}
              <Center>
                <Button
                  onClick={onSubmitHandler}
                  color={"white"}
                  ml="2"
                  bg={"#4831D4"}
                  _hover={{
                    bg: "#3FD1FF",
                    color: "#202023",
                    transform: "scale(1.05)",
                    boxShadow: "lg",
                  }}
                  isLoading={isLoading}
                >
                  Submit
                </Button>
              </Center>
            </Stack>
          </Box>
        </Flex>
      </Box>
      <Footer />
    </div>
  );
};

export default Home;
