import React, { useEffect, useState } from "react";
import {
  Image,
  Stack,
  Heading,
  Card,
  Flex,
  Box,
  Text,
  CardBody,
  Center,
  Button,
} from "@chakra-ui/react";
import Slider from "react-slick";
import img1 from "../assets/img/img1.png";
// Ensure you have appropriate CSS for .slider class if needed
import "./css/style.css";
import { wrap } from "framer-motion";
import {
  ArrowForwardIcon,
  PhoneIcon,
  HamburgerIcon,
  CloseIcon,
} from "@chakra-ui/icons";
import Header from "./component/Header";
import Footer from "./component/Footer";
import mainservice from "../Services/mainservice";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import img11 from "../assets/img/231.gif";
import poster from "../assets/img/iEltsOnam.jpg";
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';

const Product = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  let { heading } = useParams();
  const ContentID = heading

  const [product, setProduct] = useState({
    Country: "",
    Category: "",
    Content: [],
  });
  const GetProduct = async (id) => {
    try {
      // Await the product data from the service
      const productData = await mainservice.GetProductById(id);
      // Update the state with the fetched product data
      setProduct(productData.data.data);
      console.log(productData.data);
    } catch (error) {
      // Handle any errors that occur during fetch
      console.error("Error fetching product:", error);
    }
  };

  useEffect(() => {
    GetProduct(ContentID);
  }, [ContentID]);

  return (
    <div className="container">
      <Helmet>
        <title>
          Study in {product.Country} : Colleges, Scholarships, Fees | Trivandrum
        </title>
        <meta name="description" content={product.SmallIntro} />
        <meta
          property="og:title"
          content="Study Abroad | Canbridge Global | Trivandrum"
        />
        <meta
          property="og:description"
          content="Discover the story behind Trivandrum's leading Study abroad Consultancy, Canbridge can help you achieve your international education goals"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="../assets/img/abc.png" />
        <meta property="og:url" content="https://www.canbridge.in" />
        <meta property="og:locale" content="en_US" />
        <meta
          name="keywords"
          content="Study Abroad,Canbridge Global,Top Colleges,Study Abroad in Trivandrum,Scholarships"
        ></meta>
      </Helmet>
      {/* Header */}

      <Header />

      {/* cover banner */}
      <Box
        bgImage={img11}
        // bgPosition="center"
        bgRepeat="no-repeat"
        bgSize={"cover"}
        h="200"
        mt={"80px"}
      ></Box>

      <Box p={{ base: "1vw", md: "10vw" }}>
        {/* <Flex ms={'5'} p={'5%'} >
                    
                </Flex> */}

        <Flex direction={{ base: "column", md: "row" }}>
          <Box
            p={"5%"}
            pt={{ base: "10", md: "0" }}
            w={{ base: "100%", md: "65%" }}
          >
            <Stack spacing={4} mb={6}>
              <Heading size={"xl"} textAlign="center">
                Study in {product.Country}
              </Heading>
              <Text fontSize="lg" textAlign="center">
                {product.SmallIntro}
              </Text>
            </Stack>

            {product.Content.length > 0 ? (
              product.Content.map((item, index) => (
                <Stack
                  key={index}
                  p={5}
                  spacing={3}
                  // boxShadow="md"
                  // borderRadius="md"
                  mb={5}
                >
                  <Heading size={"md"} >
                    {item.Heading}
                  </Heading>
                  <Text fontSize="md">{item.Paragraph}</Text>

                  {item.List && item.List.length > 0 && (
                    <Stack as="ul" spacing={1} pl={6} listStyleType="disc">
                      {item.List.map((listItem, idx) => (
                        <Text key={idx} as="li" fontSize="md" color="gray.700">
                          {listItem}
                        </Text>
                      ))}
                    </Stack>
                  )}
                </Stack>
              ))
            ) : (
              <Text textAlign="center" color="gray.500">
                No content available for this product.
              </Text>
            )}
          </Box>

          <Box p={"10"} w={{ base: "100%", md: "30%" }}>
            <Card minW={"200px"} align="center" mb="10">
              {/* <CardHeader>
                                <Heading size='md'> Customer dashboard</Heading>
                            </CardHeader> */}
              <CardBody>
                <Heading size="md">Have any questions? Call us today!</Heading>
                <Center pt="5">
                  <Button
                    color={"white"}
                    leftIcon={<PhoneIcon />}
                    ml="2"
                    bg={"#4831D4"}
                    _hover={{
                      bg: "#3FD1FF",
                      color: "#202023",
                      transform: "scale(1.05)",
                      boxShadow: "lg",
                    }}
                  >
                    Enquire Now
                  </Button>
                </Center>
              </CardBody>
            </Card>
            <Box mb="10">
              <Heading size={"md"}>Top Universities</Heading>
              <Flex p={"2"} mt={"5"}>
                <Box minW={"200px"} ml="3" alignContent={"center"}>
                  {product.University ? (
                    product.University.map((item, index) => {
                      return (
                        <Stack>
                          <Text
                            cursor="pointer"
                            onClick={() => window.open(item.Link, "_blank")}
                            fontWeight="bold"
                            key={index}
                          >
                            {item.Name}
                            {/* <Badge ml='1' colorScheme='red'>
                                            New
                                        </Badge> */}
                          </Text>
                        </Stack>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </Box>
              </Flex>
            </Box>

            <Box minW={"200px"}>
              <Image src={poster} alt="Canbridge Poster" />
            </Box>

            {/* <Box mt='10' minW={"200px"} >
                            <Heading size={'md'}>Explore More</Heading>
                            <Flex p={'2'} mt={'5'}>
                                <Avatar size={'sm'} src='https://cdn.britannica.com/79/4479-050-6EF87027/flag-Stars-and-Stripes-May-1-1795.jpg' />
                                <Box ml='3' alignContent={'center'}>
                                    <Text fontWeight='bold'>
                                        Segun Adebayo
                                    </Text>
                                </Box>
                            </Flex>
                        </Box> */}
          </Box>
        </Flex>
      </Box>

      <Footer />
    </div>
  );
};

export default Product;
