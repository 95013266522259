import React, { useState } from 'react'

import mainservice from '../Services/mainservice'
import { Box, Flex, Input, Button, Center, Heading, Card } from '@chakra-ui/react'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react'

export default function Signin2() {
  const [form, setForm] = useState({})

  const onChangeHandler = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value
    })
    console.log(form)
  }

  async function Login(form) {
    console.log(form)
    const res = await mainservice.Login(form)
    if (res.data != null) {
      const token = res.data.token
      localStorage.setItem('user-token', JSON.stringify(token))
      window.location.reload(false)
    } else {

    }
  }

  const onSubmitHandler = (event) => {
    event.preventDefault()
    Login(form)
  }
  return (
    <>
      <Box w={'100vw'} h={'100vh'} bg={"#f0f0f0"}>
        <Flex h="100%" justifyContent={"center"} alignItems={"center"}>
          <Card p={5}>
            <FormControl>
              <Heading size={'lg'} m={'2'}>Login</Heading>
              <FormLabel>Username</FormLabel>
              <Input
                name="username"
                value={form.username || ''}
                onChange={onChangeHandler}
                placeholder="Username"
                required type='text' />
              <FormLabel>Password</FormLabel>
              <Input type="password"
                name="password"
                value={form.password || ''}
                onChange={onChangeHandler}
                placeholder="Password"
                required />
              <Center m={2}>

                <Button colorScheme='teal' size='md' onClick={onSubmitHandler}>    Submit
                </Button>
              </Center>


            </FormControl>
          </Card>
        </Flex>
      </Box>
    </>
  )
}