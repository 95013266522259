import React, { useState } from "react";
import { Heading, Box, Flex } from "@chakra-ui/react";
import { Outlet, Link } from 'react-router-dom';

export default function Dashboard() {
  const [bar, setBar] = useState(true);

  const togglebar = () => {
    setBar((prevBar) => !prevBar);
  };

  return (
    <>
      <Box borderWidth='1px' borderColor ="#dedede" h={"100vh"}>
        <Flex>
          <Box borderWidth='1px' borderColor ="#dedede"
            h="100vh" // Adjust height based on `bar` state
            w={bar ? "250px" : "75px"}
            bgColor="#f0f0f0"
            overflow="hidden" // Optional: to hide content when height is 0
            transition="width 0.3s ease" // Optional: smooth transition effect
          >
            <Heading onClick={togglebar} cursor="pointer">
              ---
            </Heading>

            <Link to={'website'}>contents</Link><br/>
            <Link to={'BlogEditor'}>create Blog</Link><br/>
            <Link to={'blogs'}>Blogs</Link>
          </Box>
          <Box borderWidth='1px' borderColor ="#dedede" h={"100vh"} w={"100%"} bgColor={"#dadfe8"}>
            <Box borderWidth='1px' borderColor ="#dedede"  bgColor="#f0f0f0" borderRadius={0} h={"75px"} >
              <Heading></Heading>
            </Box>
            <Box borderWidth='1px' borderColor ="#dedede" m={5} p={2} bgColor={'#f0f0f0'}>
            <Outlet />
            </Box>
          </Box>
        </Flex>
      </Box>
    </>
  );
}
