// src/App.js

import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";
import Redirect from "./routeProtection/ForceRedirect";
import ProtectedRoute from "./routeProtection/ProtectedRoute";
import { useDispatch, useSelector } from "react-redux";
import { isConnected, loggeduser, setRole } from "./store/loginedUser";
import mainservice from "./Services/mainservice";
import WebRoutes from "./routes/WebRoutes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Dashboard from "./Pages/dashboard";

const App = () => {
  const dispatch = useDispatch();
  const active = useSelector((state) => state.loginedUser.isConnected);

  async function Auth() {
    if (typeof window !== "undefined") {
      const token = JSON.parse(localStorage.getItem("user-token"));
      if (token) {
        const data = { token: token };
        const res = await mainservice.Auth(data);
        if (res.data != null) {
          // console.log(res.data);
          dispatch(loggeduser(res.data._id));
          dispatch(isConnected());
          dispatch(setRole("user"));
          // fetchData(res.data._id)
        } else {
          console.log("error");
        }
      }
    }
  }
  useEffect(() => {
    Auth();
  });

  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />}>
            {protectedRoutes.map((route, index) => {
              return (
                <Route
                  path={`/dashboard${route.path}`}
                  element={
                    <ProtectedRoute user={active}>
                      {route.element}
                    </ProtectedRoute>
                  }
                  key={index}
                />
              );
            })}
          </Route>
          {publicRoutes.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={<Redirect user={active}> {route.element} </Redirect>}
                key={index}
              />
            );
          })}
          {WebRoutes.map((route, index) => {
            return (
              <Route path={route.path} element={route.element} key={index} />
            );
          })}
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;


// const App = () => {
//   return (
//     <h1>Hello SSR </h1>
//   )}
// export default App;