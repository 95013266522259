import React from "react";
import { Navigate} from "react-router-dom";


const ProtectedRoute = ({ user, children }) => {
  console.log(user)
  if (!user) {
    return <Navigate to="/desk" replace />;
  }
  return children;
};

export default ProtectedRoute;