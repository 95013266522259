import React, { useEffect, useState } from "react";
import {
  Image,
  Stack,
  Heading,
  Flex,
  Box,
  Text,
  Center,

} from "@chakra-ui/react";


import {
  RiFacebookBoxFill,
  RiInstagramFill,
  RiLinkedinBoxFill,
  RiYoutubeFill,
} from "@remixicon/react";

import logo from "../../assets/img/logo.png";

// Ensure you have appropriate CSS for .slider class if needed
import "../css/style.css";


import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box
      bgColor={"#202026"}
      w={"100vw"}
      mb="120"
      className="footer"
      color={"white"}
    >
      <Flex
        justifyContent={"space-between"}
        p="12"
        direction={{ base: "column", md: "row" }}
      >
        <Box w={{ base: "100%", md: "25%" }} p="2" m={"2"}>
          <Stack>
            <Box bgColor={"white"} p={"5"}>
              <Center>
                <Image w="180px" src={logo} alt="CanbridgeLOGO" />
              </Center>
            </Box>
            <br />
            <Heading size={"sm"}>About us</Heading>
            <Text>
              To provide a transparent and efficient service network to our
              students and institutions, while nurturing a delightful
              environment for our teams to flourish, personally and
              professionally.
            </Text>
          </Stack>
        </Box>
        <Box w={{ base: "100%", md: "25%" }} p="2" m={"2"}>
          <Stack>
            <Heading size={"sm"}>Login to</Heading>
            <Text>Candidate Login</Text>
            <br />
            <Heading size={"sm"}>About Company</Heading>
            <a href="/aboutus">
              <Text>About</Text>
            </a>
            <Text>Career</Text>
            <Text>News & Events</Text>
            <Text>Contact Us</Text>
          </Stack>
        </Box>
        <Box w={{ base: "100%", md: "25%" }} p="2" m={"2"}>
          <Stack>
            <Heading size={"sm"}>Services</Heading>
            <Text>Education Consultancy</Text>
            <Text>Study Abroad</Text>
            <Text>Counselling</Text>
            <Text>Test Prepration</Text>
            <Text>Scholarships</Text>
            <Text>Immigration Assistance</Text>
          </Stack>
        </Box>
        <Box w={{ base: "100%", md: "25%" }} p="2" m={"2"}>
          <Stack>
            <Heading size={"sm"}>Contact Us</Heading>
            <Text>info@canbridge.in</Text>
            <Text>+91 889009722</Text>
            <Text>+91 789989678</Text>
            <br />
            <Heading size={"sm"}>Follow Us</Heading>
            <Box display={"flex"}>
              <RiFacebookBoxFill
                size={24} // set custom `width` and `height`
                color="white" // set `fill` color
                onClick={() =>
                  (window.location.href =
                    "https://www.facebook.com/canbridgeglobal")
                }
              />
              <RiInstagramFill
                size={24} // set custom `width` and `height`
                color="white" // set `fill` color
                onClick={() =>
                  (window.location.href =
                    "https://www.instagram.com/canbridge__global/")
                }
              />
              <RiYoutubeFill
                size={24} // set custom `width` and `height`
                color="white" // set `fill` color
                onClick={() =>
                  (window.location.href =
                    "https://www.youtube.com/@CanbridgeImmigration")
                }
              />
              <RiLinkedinBoxFill
                size={24} // set custom `width` and `height`
                color="white" // set `fill` color
                onClick={() =>
                  (window.location.href =
                    "https://in.linkedin.com/company/canbridge-global-emigration-services-pvt-ltd")
                }
              />
            </Box>
          </Stack>
        </Box>
      </Flex>
    </Box>
  );
};

export default Footer;
